import {React,useEffect,useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ChooseLayoutShimmer from './chooseLayoutShimmer';


export default function ChooseLayout({auctionData}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [selectedTemplate, setSelectedTemplate] = useState(localStorage.getItem('selectedTemplateId') ? 
        parseInt(localStorage.getItem('selectedTemplateId')) : null);
    const auctionTemplates = [
        {   id: 1, 
            name: 'Classic', 
            description: 'A timeless design to complement any website.', 
            img_url: '/images/classic-template.png' 
        },
        { 
            id: 2, 
            name: 'Single Lot', 
            description: 'A simple and powerful layout for single-lot auctions.', 
            img_url: '/images/singlelot-template.png' },
        { 
            id: 3, 
            name: 'Standalone', 
            description: 'A homepage-inspired design, ideal for standalone auctions.', 
            img_url: '/images/standalone-template.png' },
    ];
    
    /**
     * The function `handleTemplateSelect` sets the selected template to the provided template ID.
     */
    const handleTemplateSelect = (templateId) => {
        setSelectedTemplate(templateId);
    };
    useEffect(()=>{
        setTimeout(() => {
            if(auctionData!==undefined){
                setSelectedTemplate(localStorage.getItem('selectedTemplateId')===null?
                    auctionData.template_name:
                    parseInt(localStorage.getItem('selectedTemplateId')))
                setLoading(false)
            }
            else{
                setLoading(false)
            }
        }, 1800);
    },[])

    return (
        !loading ? 
            <div className=" overflow-x-hidden flex justify-center ">
                <div className=" md:my-[60px] my-[29px]">
                    <p className="sm:text-[34px] text-[25px] leading-normal text-center text-[#343434] font-medium">
                        Choose your auction template</p>
                    <ul className="flex justify-center flex-wrap md:mt-10 mt-3">
                        {auctionTemplates.map((template) => (
                            <>
                                <div className={`${template.id!==3?'lg:mr-[-64px] sm:mb-0 mb-7':''} w-auto`} key={template.id}>
                                    <label id='classic'>
                                        <input
                                            className='hidden'
                                            type="radio"
                                            name="template"
                                            value={template.id}
                                            checked={selectedTemplate === template.id}
                                            onChange={() => {handleTemplateSelect(template.id)}}
                                        />
                                        <div id='classic-template' className="relative classic-div">
                                            <img src={template.img_url} 
                                                className={`classic-img 
                                            ${selectedTemplate === template.id ? 
                                'brightness-50 cursor-default': 'cursor-pointer'}`}
                                                alt="classic-img" loading="lazy" />
                                            <div className={`absolute classic-btns top-[40%] left-[23%] 
                                        ${selectedTemplate === template.id?'sm:block hidden':'hidden'}`}>
                                                <button className="border border-[#FFFFFF] rounded-md text-[14px] text-[#fff] 
                                    leading-normal font-medium px-[31px] py-[12px] w-[233px]">Preview</button>
                                                <button type='button' 
                                                    onClick={()=>{localStorage.setItem('selectedTemplateId',template.id),
                                                    navigate(template.id===1?(auctionData===undefined?
                                                        "/auctions/create/classic":
                                                        `/auctions/edit/${auctionData.auction_id}/classic`):
                                                        (template.id===2?(auctionData===undefined?
                                                            "/auctions/create/single-lot":
                                                            `/auctions/edit/${auctionData.auction_id}/single-lot`):
                                                            (auctionData===undefined?'/auctions/create/standalone':
                                                                `/auctions/edit/${auctionData.auction_id}/standalone`)),
                                                    {state: auctionData===undefined?'':auctionData})}}>
                                                    <p id="continue" data-testid='continue'
                                                        className="bg-[#282828] rounded-md 
                                    text-[14px] text-[#fff] leading-normal mt-2.5 font-medium px-[20px] py-[12px] w-[233px] 
                                    continue-template">
                                                        Continue with this template</p>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-[-50px]">
                                            <p className="text-center sm:text-[22px] text-[20px] text-[#343434] 
                                    font-medium leading-normal">
                                                {template.name}</p>
                                            <p className="text-center sm:text-[16px] text-[15px] text-[#343434] 
                                    font-medium leading-normal m-auto max-w-[325px]">
                                                {template.description}</p>
                                            <div className={`${selectedTemplate === template.id?'block md:hidden':
                                                'hidden'} mt-2 flex 
                                        flex-col justify-center items-center`}>
                                                <button className="border border-[#A1A1A9] rounded-md text-[14px] 
                                        text-[#52525A] leading-normal 
                                    font-medium px-[31px] py-[5px] w-[233px]">Preview</button>
                                                <button type='button' 
                                                    onClick={()=>{localStorage.setItem('selectedTemplateId',template.id),
                                                    navigate(template.id===1?(auctionData===undefined?
                                                        "/auctions/create/classic":
                                                        `/auctions/edit/${auctionData.auction_id}/classic`):
                                                        (template.id===2?(auctionData===undefined?
                                                            "/auctions/create/single-lot":
                                                            `/auctions/edit/${auctionData.auction_id}/single-lot`):
                                                            (auctionData===undefined?'/auctions/create/standalone':
                                                                `/auctions/edit/${auctionData.auction_id}/standalone`)),
                                                    {state: auctionData===undefined?'':auctionData})}}>
                                                    <p onClick={()=>{localStorage.setItem('selectedTemplateId',template.id)}} 
                                                        className="bg-[#282828] rounded-md text-[14px] text-[#fff] 
                                            leading-normal mt-2.5 
                                    font-medium px-[20px] py-[5px] w-[233px]">Continue with this template</p>
                                                </button>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </>
                        ))}
                    </ul>
                </div>
            </div>
            : <ChooseLayoutShimmer/>  
    )
}
