/* eslint-disable react/no-unescaped-entities */
import React, {useState, useEffect} from "react";
import AnimatedPage from "../components/Animation/AnimatedPage";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Layout from "../components/layout";
import SetNewPassword from "../pages/auth/SetNewPassword";
import { useDispatch } from 'react-redux';
import { Suspense, lazy } from "react";
import CommonLoader from "../CommonComponent/commonLoader";
import ViewProfileShimmer from "../pages/account/Profile/ViewProfile/profileShimmer";
import PricingShimmer from "../pages/Pricing/pricingShimmer";
import Register from "../pages/auth/RegistrationForm";
import ListingAuction from "../pages/Auction/listAuction";
import CreateAuction from "../pages/Auction/createAuction";
import ClassicTemplate from "../pages/Auction/createAuction/reviewPages";
import CreateLot from "../pages/Auction/createLot";
import AuctionView from "../pages/Auction/auctionView";
import Lots from "../pages/Auction/auctionView/lots";
import Overview from "../pages/Auction/auctionView/overview";
import Bidders from "../pages/Auction/auctionView/bidders";
import Insights from "../pages/Auction/auctionView/insights";
import OverViewShimmer from "../pages/Auction/auctionView/overview/overViewShimmer";
import BidderDetails from "../pages/Auction/auctionView/bidders/bidderDetails";
import Sales from "../pages/Auction/auctionView/sales";
import OrderDetails from "../pages/Auction/auctionView/orderDetails";
// import ViewBids from "../pages/allBidders/viewBids";
import ViewBidsOfLot from "../pages/Auction/auctionView/lots/view";
import Bids from "../pages/Auction/auctionView/bids/bids";
import BidsList from "../pages/Auction/auctionView/bids";
import BidsShimmer from "../pages/Auction/auctionView/bids/bidsShimmer";
import AllBidders from "../pages/AllBidders";
import Custom404 from "../404";
import { Auth } from "aws-amplify";
import { Oval } from "react-loader-spinner";
import ErrorBoundary from "../ErrorBoundary";
const Login = lazy(()=> import('../pages/auth/LoginForm')) 
const ResetPassword = lazy(()=>import('../pages/auth/ResetPassword'))
const Profile = lazy(() => import('../pages/account/Profile'))    
const Pricing = lazy(() => import('../pages/Pricing'))    
const Dashboard = lazy(()=>import('../pages/Dashboard'))


export default function NavigationRoutes(props) {
    const location = useLocation();
    const auth = useSelector(state=>state.registration);
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch()
    const { loggedIn } = auth;
    const navigate= useNavigate()

    const [isTopbarIsOpen, setIsTopBarIsOpen] = useState(true);

    const selectedTemplate = localStorage.getItem('selectedTemplateId')
    return (
        <Routes location={location} key={location.pathname}>
            <Route
                path="*"
                element={(
                    <main style={{ padding: '1rem' }}>
                        <p>There's nothing here!</p>
                        <a><Navigate to="/" replace /></a>
                    </main>
                )}
            />
            <Route
                path="/page-not-found"
                element={(
                    <main style={{ padding: '1rem' }}>
                        <Custom404 />
                        {/* <a><Navigate to="/" replace /></a> */}
                    </main>
                )}
            />
            <Route path="/verify_token" element={
                <AnimatedPage><SetNewPassword/></AnimatedPage>
            } />
            {!loggedIn ? (
                <>
                    <Route path="/" element={
                        <Suspense fallback={<CommonLoader/>}>
                            <AnimatedPage>
                                <Register />
                            </AnimatedPage>
                        </Suspense>}
                    />
                    <Route path="/login" element={
                        <Suspense fallback={<CommonLoader/>}>
                            <AnimatedPage>
                                <Login/>
                            </AnimatedPage>
                        </Suspense>
                    }/>
                    <Route path="/reset-password" element={
                        <Suspense fallback={<CommonLoader/>}>
                            <AnimatedPage>
                                <ResetPassword/>
                            </AnimatedPage>
                        </Suspense>}/>
                </>
            )
                : (
                    <>
                        <Route element={
                            <Layout isTopbarIsOpen={isTopbarIsOpen} 
                                setIsTopBarIsOpen={setIsTopBarIsOpen} {...props} />
                        }>
                            <Route path="/" element={
                                // <ErrorBoundary>
                                <Suspense fallback={<ViewProfileShimmer/>}>
                                    <Dashboard/>
                                </Suspense>
                                // </ErrorBoundary>
                            } />
                            <Route path="/account" element={
                                // <ErrorBoundary>
                                <Suspense fallback={<ViewProfileShimmer/>}>
                                    {/* <AnimatedPage> */}
                                    <Profile/>
                                    {/* </AnimatedPage> */}
                                </Suspense>
                                // </ErrorBoundary>
                            } />
                            <Route path="/pricing" element={
                                <Suspense fallback={<PricingShimmer/>}>
                                    {/* <AnimatedPage> */}
                                    <Pricing/>
                                    {/* </AnimatedPage> */}
                                </Suspense>} />
                            <Route path="/auctions" element={
                                // <ErrorBoundary>
                                <Suspense fallback={<PricingShimmer/>}>
                                    <AnimatedPage>
                                        <ListingAuction/>
                                    </AnimatedPage>
                                </Suspense>
                                // </ErrorBoundary>
                            } />
                            <Route path="/allbidders" element={
                                <Suspense fallback={<PricingShimmer/>}>
                                    <AnimatedPage>
                                        <AllBidders />
                                    </AnimatedPage>
                                </Suspense>} /> 
                            <Route path="/auctions/:id/:uid/lots/view/:lotId" element={
                                <Suspense fallback={<PricingShimmer/>}>
                                    <AnimatedPage>
                                        <ViewBidsOfLot/>
                                    </AnimatedPage>
                                </Suspense>} />
                            <Route element={<AuctionView {...props}/>}>
                                <Route path="/auctions/:id/:uid/lots" element={
                                    <Lots/>
                                } />
                                <Route path="/auctions/:id/:uid/overview" element={
                                    // <Suspense fallback={<OverViewShimmer/>}>
                                    <AnimatedPage>
                                        <Overview/>
                                    </AnimatedPage>
                                    // </Suspense>
                                } />
                                <Route path="/auctions/:id/:uid/bidders" element={
                                    <Bidders/>
                                } />
                                <Route path="/auctions/:id/:uid/bids" element={
                                    <Suspense fallback={<BidsShimmer/>}>
                                        <BidsList/>
                                    </Suspense>

                                } />
                                <Route path="/auctions/:id/:uid/insights" element={
                                    <Insights/>
                                } />
                                <Route path="/auctions/:id/:uid/sales" element={
                                    <Sales/>
                                } />
                            </Route>
                            <Route path="/auctions/:id/:uid/order-details/:id" element={
                                <OrderDetails/>
                            } />
                            <Route path="/auctions/:id/:uid/bidders/:bidderId" element={
                                <BidderDetails/>
                            } />
                        </Route>
                        <Route path="/auctions/create" element={
                            <Suspense fallback={<PricingShimmer/>}>
                                <AnimatedPage>
                                    <CreateAuction/>
                                </AnimatedPage>
                            </Suspense>} />
                        
                        <Route path="/auctions/edit/:id" element={
                            <Suspense fallback={<PricingShimmer/>}>
                                <AnimatedPage>
                                    <CreateAuction edit/>
                                </AnimatedPage>
                            </Suspense>} />
                        <Route path="/auctions/:id/:uid/lots/create" element={
                            <Suspense fallback={<PricingShimmer/>}>
                                <AnimatedPage>
                                    <CreateLot/>
                                </AnimatedPage>
                            </Suspense>} />
                        <Route path="/auctions/:id/:uid/lots/:lotNumber" element={
                            <Suspense fallback={<PricingShimmer/>}>
                                <AnimatedPage>
                                    <CreateLot edit/>
                                </AnimatedPage>
                            </Suspense>} />

                        <Route 
                            path={`/auctions/create/${selectedTemplate==1?
                                'classic':(selectedTemplate==2?'single-lot':'standalone')}`} 
                            element={
                                <Suspense fallback={<PricingShimmer/>}>
                                    <AnimatedPage>
                                        <ClassicTemplate/>
                                    </AnimatedPage>
                                </Suspense>
                            }
                        />
                        <Route 
                            path={`/auctions/edit/:id/:uid/${selectedTemplate==1?
                                'classic':(selectedTemplate==2?'single-lot':'standalone')}`} 
                            element={
                                <Suspense fallback={<PricingShimmer/>}>
                                    <AnimatedPage>
                                        <ClassicTemplate/>
                                    </AnimatedPage>
                                </Suspense>
                            }
                        />
                    </>
                )
            }
        </Routes>
    );
}
