/* eslint-disable security/detect-object-injection */
import React, { useCallback, useEffect, useState } from 'react'
import ListingHeader from './listingHeader'
import ListingBody from './listingBody'
import { dataService } from '../../../services/dataService'
import { useLocation, useSearchParams } from 'react-router-dom'
import Paginate from './paginate'

export default function ListingAuction() {
    const [searchParams, setSearchParams] = 
        useSearchParams({ page:  1, per_page: 10});
    const [auctionData,setAuctionData]= useState([]);
    const [loading, setLoading]=useState(false);
    const [isOpen,setIsOpen]=useState(false);
    const [auction,setAuction]= useState([]);
    const [message, setMessage]=useState("");
    const [loader,setLoader]=useState(false);
    const [isFilterApplied, setIsFilterApplied]=useState(false);
    const location = useLocation();
    const [profile,setProfile]=useState({})
    const [currentSortColumn, setCurrentSortColumn] = useState({key:null, order: 'asc'});
    const user = (localStorage.getItem('user'));
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isCancelModalOpen,setIsCancelModalOpen] = useState(false)
    const [domain,setDomain]=useState('');
    const [date, setDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selected, setSelected] = useState('')
    const [errorMessage, setErrorMessage] = useState("");

    /* The `fetchHandler` function is a callback function that is used to fetch the user's profile
    data. It is called when the component mounts or when there is a change in the dependency array
    (which is empty in this case). */
    const fetchHandler = useCallback(async()=>{
        const body = {
            "email": user
        }
        const response = await dataService.ViewProfile(body);
        setProfile(response.data.data.data);
    },[])

    const viewDomain=async()=>{
        const endpoint="?view=True";
        const body={}
        const response=await dataService.SubDomainSetup(endpoint,body);
        setDomain(response.data.subdomain);
    }

    useEffect(()=>{
        fetchHandler()
        viewDomain()
        if(searchParams.get('status')!==null || searchParams.get('start_date')!==null){
            setIsFilterApplied(true);
        }
    },[fetchHandler])

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
       
        // const date = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
        return formattedDate
       
    };

    /* The above code is a React useEffect hook that is used to perform side effects in a functional
    component. */
    useEffect(()=>{
        if(localStorage.getItem('AuctionDelete')==='success'){
            setMessage('Auction deleted successfully');
            setTimeout(() => {
                setMessage('')
            }, 4000)
            localStorage.removeItem('AuctionDelete');
        }

        if(localStorage.getItem('selectedTemplateId')!==null){
            localStorage.removeItem('selectedTemplateId');
        }
        if(localStorage.getItem('cloneAuction')==='true'){
            setMessage('Auction has been successfully Cloned and added to your Auction list');
            setTimeout(() => {
                setMessage('')
                localStorage.setItem('cloneAuction','false');
            }, 4000);
        }

    },[])

    let endpoint = `?page=${Number(searchParams.get('page'))}&per_page=${searchParams.get('per_page')}`

    /* The `handleFetch` function is a callback function that is used to fetch auction data based on
    the search parameters. It is called whenever there is a change in the search parameters or when
    the component mounts. */
    const handleFetch=useCallback(async()=>{
        setLoading(true)
        if((searchParams.get('keyword')=== null && auctionData.length===0)){
            setLoading(true);
        }
        if(searchParams.get('keyword')!==null){
            endpoint += `&keyword=${searchParams.get('keyword')}`
        }
        if(searchParams.get('sort')!==null){
            // eslint-disable-next-line max-len
            endpoint += `&sort=${String(searchParams.get('sort'))}&key=${String(searchParams.get('key'))}&order=${String(searchParams.get('order'))}`
        }if(searchParams.get('status')!==null){
            endpoint += `&status=${searchParams.get('status')}`
        }if(searchParams.get('start_date')!==null){
            endpoint += `&start_date=${searchParams.get('start_date')}&end_date=${searchParams.get('end_date')}`
            setDate(formatDate(Number(searchParams.get('start_date'))))
            setToDate(formatDate(Number(searchParams.get('end_date'))))
        }if(searchParams.get('export') !==null){
            setLoader(true);
            endpoint += `&export=${1}`
        }
        const data=await dataService.AuctionList(endpoint)

        console.log(data, 'auction data')
        if(data.error===false){
            setAuctionData(data.data.data);
            setAuction(data.data);
            setLoading(false);
            if(data.data?.csv_url !== undefined){
                window.open(data.data.csv_url, '_blank');
                let params = Object.fromEntries(searchParams);
                delete params.export
                setSearchParams({...params})
                setIsOpen(false);
                setMessage("CSV successfully exported");
                setLoader(false);
                setTimeout(() => {
                    setMessage("");
                }, 3000);
            }
        }else{
            setLoading(false);
        }
    },[location])

    const fetchAuctions=async()=>{
        if((searchParams.get('keyword')=== null && auctionData.length===0)){
            setLoading(true);
        }
        if(searchParams.get('keyword')!==null){
            endpoint += `&keyword=${searchParams.get('keyword')}`
        }
        if(searchParams.get('sort')!==null){
            // eslint-disable-next-line max-len
            endpoint += `&sort=${String(searchParams.get('sort'))}&key=${String(searchParams.get('key'))}&order=${String(searchParams.get('order'))}`
        }if(searchParams.get('status')!==null){
            endpoint += `&status=${searchParams.get('status')}`
        }if(searchParams.get('start_date')!==null){
            endpoint += `&start_date=${searchParams.get('start_date')}&end_date=${searchParams.get('end_date')}`
            setDate(formatDate(Number(searchParams.get('start_date'))))
            setToDate(formatDate(Number(searchParams.get('end_date'))))
        }if(searchParams.get('export') !==null){
            setLoader(true);
            endpoint += `&export=${1}`
        }
        const data=await dataService.AuctionList(endpoint)
        if(data.error===false){
            setAuctionData(data.data.data);
        }
    }

    /**
     * The handleSearch function updates the search parameters based on the provided key and value, and
     * then sets the updated search parameters.
     */
    const handleSearch=(e, key, value)=>{
        e.preventDefault();
        let params = Object.fromEntries(searchParams);
        params.page = 1
        if(key === 'keyword') {
            params[key] = encodeURIComponent(value); 
        }else {
            params[key] = value
        }
        if(params['keyword'] === '') delete params.keyword
        setSearchParams({ ...params})
    }

    /**
     * The function `handleSort` is used to update the search parameters based on the sorting key and
     * order.
     */
    const handleSort=(e,key)=>{
        let params = Object.fromEntries(searchParams);
        if(searchParams.get('sort') === false || searchParams.get('sort') === null) {
            params['sort'] = 'true'
            params['key'] = `${key}`
            params['order'] = `ascending`
            setCurrentSortColumn({
                key: key,
                order: 'asc'
            });
        }else {
            if(searchParams.get('key').includes(key)) 
            {
                if(searchParams.get('order')==='ascending') {
                    params['order'] = `descending`
                    setCurrentSortColumn({
                        key: key,
                        order: 'desc'
                    });
                }else{
                    params['order'] = `ascending`
                    setCurrentSortColumn({
                        key: key,
                        order: 'asc'
                    });
                }
            }
            else {
                params['key'] = key
                params['order'] = `ascending`
                setCurrentSortColumn({
                    key: key,
                    order: 'asc'
                });
            }
        }
        setSearchParams({ ...params})
    }

    /**
     * The function `handleAddNote` is an asynchronous function that adds a note to an auction and
     * updates the data.
     */
    const handleAddNote=async(auction_id,note)=>{
        const body={
            note: note,
        }
        let url = `?auction_id=${auction_id}`
        if(note===''){
            url += '&del=1'
        }
        const data=await dataService.AddNotes(url,body);
        handleFetch()
    }

    /**
     * The function `handleFilter` takes in three parameters (status, fromDate, toDate) and updates the
     * search parameters based on the values of these parameters.
     */
    const handleFilter=(status, fromDate, toDate)=>{
        let params = Object.fromEntries(searchParams);
        params['page'] = 1
        setSearchParams({ ...params, page: 1 })
        if(status==='' && fromDate==='' && toDate===''){
            return;
        }
        if(status.status!==undefined){
            params['status']=status.status.replaceAll("%20", ' ');
            setIsFilterApplied(true);
        }else{
            delete params.status;
        }
        if(fromDate!==''){
            const fromDateStartOfDay = new Date(fromDate);
            fromDateStartOfDay.setHours(0, 0, 0, 0); // Set to 12:00 AM
            const fromDateTimestamp = Math.floor(fromDateStartOfDay.getTime());
            params['start_date']=fromDateTimestamp;
            setIsFilterApplied(true);
        }else{
            delete params.start_date
        }
        if(toDate!==''){
            const toDateEndOfDay = new Date(toDate);
            toDateEndOfDay.setHours(23, 59, 59, 999); // Set to 11:59 PM
            const toDateTimestamp = Math.floor(toDateEndOfDay.getTime());
            params['end_date']=toDateTimestamp;
        }else{
            delete params.end_date
        }
        if (params['status'] === undefined) delete params.status;
        setSearchParams({...params})
    }

    /**
     * The function `cloneHandler` clones an auction by sending a request to the
     * `dataService.CloneAuction` API, and if the response is successful, it displays a success
     * message, clears the message after 4 seconds, and calls the `handleFetch` function.
     */
    const cloneHandler=async(auctionId)=>{
        const body={
            "auction_id": auctionId,
        }
        const response = await dataService.CloneAuction(body);
        if(response.error===false){
            setMessage('Auction has been successfully Cloned and added to your Auction list');
            let params = Object.fromEntries(searchParams);
            params['page'] = 1
            setSearchParams({ ...params, page: 1 })
            setTimeout(() => {
                setMessage('')
            }, 4000);
            // handleFetch()   
        }
    }

    /**
     * The function `handleExport` updates the search parameters by adding a key-value pair with the
     * key "export" and the value 1.
     */
    const handleExport = () => {
        let params = Object.fromEntries(searchParams);
        params['export'] = 1
        setSearchParams({...params})
    }

    /**
     * The function `handleDelete` is an asynchronous function that deletes an auction, updates the
     * state, and displays a success message.
     */
    const handleDelete = async(id)=>{
        setLoader(true)
        const data = await dataService.DeleteAuction(id)
        if(data.error===false){
            setLoader(false)
            setIsDeleteModalOpen(false)
            setMessage('Auction deleted successfully');
            setTimeout(() => {
                setMessage('')
            }, 4000);
            handleFetch()
            window.scroll(0,0)
        }
    }

    /**
     * The function `handleDeactivate` deactivates an auction by sending a request to the server and
     * updating the state accordingly.
     */
    const handleCancel = async(auctionId)=>{
        setLoader(true)
        const body={
            "type": "CANCEL",
        }
        const response = await dataService.UnpublishAuction(auctionId,body);
        if(response.error==false){
            setLoader(false)
            setIsCancelModalOpen(false)
            setMessage('Auction cancelled successfully');
            setTimeout(() => {
                setMessage('')
            }, 4000);
            handleFetch() 
            window.scroll(0,0)  
        }
    }

    /**
     * The function updates the search parameters with a new page number when a page number is clicked.
     */
    const handleOnClickPageNumber = (pageNum) => {
        const params = Object.fromEntries(searchParams)
        setSearchParams({ ...params, page: pageNum.selected + 1 })
    }

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
   `useEffect` hook is used to call the `handleFetch` function when the component mounts or when the
   `handleFetch` function changes. This ensures that the auction data is fetched and updated
   whenever there is a change in the search parameters or when the component mounts. */
    useEffect(()=>{
        handleFetch()
    }, [handleFetch])

    return (
        <div className="sm:mx-10 mx-5 sm:my-8 mt-8 mb-20">
            <ListingHeader auctionData={auctionData}
                date={date}  setDate={setDate}
                toDate={toDate} setToDate={setToDate} 
                selected={selected} setSelected={setSelected}
                profile={profile}
                message={message}
                loading={loading}
                isOpen={isOpen}
                auction={auction}
                setIsOpen={setIsOpen}
                loader={loader}
                setMessage={setMessage}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                handleSearch={handleSearch} 
                handleFilter={handleFilter} 
                handleFetch={handleFetch}
                handleExport={handleExport}
                isFilterApplied={isFilterApplied}
                setIsFilterApplied={setIsFilterApplied}
                errorMessage={errorMessage}/>
            <div className="pb-5 pt-2 overflow-auto">
                <ListingBody auctionData={auctionData}
                    setErrorMessage={setErrorMessage}
                    fetchAuctions={fetchAuctions}
                    handleSort={handleSort}
                    domain={domain}
                    loading={loading}
                    setMessage={setMessage}
                    setLoader={setLoader}
                    auction={auction}
                    handleFetch={handleFetch}
                    handleAddNote={handleAddNote}
                    isFilterApplied={isFilterApplied}
                    searchParams={searchParams}
                    currentSortColumn={currentSortColumn}
                    cloneHandler={cloneHandler}
                    isDeleteModalOpen={isDeleteModalOpen}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    isCancelModalOpen={isCancelModalOpen}
                    setIsCancelModalOpen={setIsCancelModalOpen}
                    handleDelete={handleDelete}
                    handleCancel={handleCancel}
                    loader={loader}
                />
            </div>
            {(auction.current_page <= auction.total_pages) &&
            <Paginate totalPageNumber={Math.ceil(auction.total_records_found/10)}
                selectedPageNumber={Number(searchParams.get('page')) || 1}
                handleOnClickPageNumber={handleOnClickPageNumber} />}
        </div>
    )
}
