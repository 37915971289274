/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
/* eslint-disable no-unused-vars */
import React, {useCallback, useEffect, useState} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import TableHeader from './listingHeader';
import TableBody from './listingBody';
import Paginate from './paginate';
import { dataService } from '../../services/dataService';
import ToastMeassage from '../../CommonComponent/toastMeasseage';
import ModalBidder from './modalBidders';

export default function AllBidders() {
    const [searchParams, setSearchParams] = 
    useSearchParams({ page:  1, sort_key : "created_at", sort_order : "descending"});
    const [bidders,setBidders]= useState([]);
    const [loading, setLoading]=useState(false);
    const [biddersInfo,setBiddersInfo]= useState([]);
    const [message, setMessage]=useState("");
    const [isFilterApplied, setIsFilterApplied]=useState(false);
    const location = useLocation();
    const [profile,setProfile]=useState({})
    const [currentSortColumn, setCurrentSortColumn] = useState({key:null, sort_order: 'ascending'});
    let endpoint = `?page_number=${Number(searchParams.get('page'))===0? 1: Number(searchParams.get('page'))}`
    const [ isOpen,setIsOpen] = useState(false);
    const [showExportModel, setShowExportModel] =useState('')
    const [exportBtnLoading, setExportBtnLoading]=useState(false)
    const [toastMeasseage, setToastMeassage] = useState("");
    const [loader,setLoader]=useState(false);
    const [bidderExportCsvUrl, setBidderExportCsvUrl]=useState('')

    const exportHandler=()=>{
        if(!exportBtnLoading){
            setExportBtnLoading(true)
            window.location.href = bidderExportCsvUrl
        }
        setTimeout(()=>{
            setExportBtnLoading(false)
            setShowExportModel(false)
            setToastMeassage(true)
        }, 1500)
        setTimeout(()=>{
            setToastMeassage(false)
        },3000)
    }

  
    /* The `handleFetch` function is a callback function that is used to fetch auction data based on
the search parameters. It is called whenever there is a change in the search parameters or when
the component mounts. */
    const handleFetch=useCallback(async()=>{
        setLoading(true)
        if((searchParams.get('keyword')=== null && bidders.length===0)){
            setLoading(true);
        }
        if((searchParams.get('exportBidder')!== null)){
            endpoint += `&export=${true}`
        }
        if(searchParams.get('keyword')!==null){
            endpoint += `&search=${searchParams.get('keyword')}`
        }
        if(searchParams.get('sort_key')!==null){
            endpoint += `&sort_by=${String(searchParams.get('sort_key'))}&sort_order=${String(searchParams.get('sort_order'))}`
        }if(searchParams.get('status')!==null){
            endpoint += `&status=${searchParams.get('status').replaceAll("+", ' ')}`
        }if(searchParams.get('start_date')!==null){
            endpoint += `&start_date=${searchParams.get('start_date')}&end_date=${searchParams.get('end_date')}`
        }
        const response=await dataService.AllBidderList(endpoint)
        if(response.error===false){
            setBidders(response.data.buyers);
            setBiddersInfo(response.data);
            setLoading(false);
            if(response.data?.csv_url !== ""){
                window.open(response.data.csv_url, '_blank');

                let params = Object.fromEntries(searchParams);
                delete params.exportBidder
                setSearchParams({...params})
                setIsOpen(false);
                setMessage("Export complete");
                delete params.exportBidder
                setLoader(false);
                setTimeout(() => {
                    setMessage("");
                }, 3000);
            }
        }else{
            setLoading(false);
        }
         
        
    },[location])

    /**
 * The handleSearch function updates the search parameters based on the provided key and value, and
 * then sets the updated search parameters.
 */
    const handleSearch=(e, key, value)=>{
        e.preventDefault();
        let params = Object.fromEntries(searchParams);
        params.page = 1
        if(key === 'keyword') {
            params[key] = encodeURIComponent(value); 
        }else {
            params[key] = value
        }
        if(params['keyword'] === '') delete params.keyword
        setSearchParams({ ...params})
    }

    const handleExport = () => {
        let params = Object.fromEntries(searchParams);
        setLoader(true);
        params['exportBidder'] = true
        setSearchParams({...params})
    }

    /**
 * The function `handleSort` is used to update the search parameters based on the sorting key and
 * order.
 */
    const handleSort=(e,key)=>{
        let params = Object.fromEntries(searchParams);
        if(searchParams.get('sort_key') ==null) {
            params['sort_key'] = `${key}`
            params['sort_order'] = `ascending`
            setCurrentSortColumn({
                key: key,
                sort_order: 'ascending'
            });
        }else {
            if(searchParams.get('sort_key')==key) 
            {
                if(searchParams.get('sort_order')==='ascending') {
                    params['sort_order'] = `descending`
                    setCurrentSortColumn({
                        key: key,
                        sort_order: 'descending'
                    });
                }else{
                    params['sort_order'] = `ascending`
                    setCurrentSortColumn({
                        key: key,
                        sort_order: 'ascending'
                    });
                }
            }
            else {
                params['sort_key'] = key
                params['sort_order'] = `ascending`
                setCurrentSortColumn({
                    key: key,
                    sort_order: 'ascending'
                });
            }
        }
        setSearchParams({ ...params})
    }

    const [modalOpen,setModalOpen]=useState({isModalOpen: false,
        id: '',
        type: ''
    });


    // const approveBidderHandler=async(item, status)=>{
    //     setLoader(true);
    //     const body={
    //         status: status,
    //         seller_email: auction.seller_email,
    //         auction_id: item.auction_id,
    //         first_name: item.first_name,
    //         email_address: item.email_address
    //     }
    //     const response= await dataService.approveBidders(item._id,body);
    //     setLoader(false);
    //     setModalOpen({isModalOpen: false, type: '', id: ''})
    //     setShowToastMessage(`Bidder has been ${status.toLowerCase()} `)
    //     setTimeout(()=>{
    //         setShowToastMessage("");
    //     },4000)
    //     fetchHandler()
    // }


    /**
 * The function updates the search parameters with a new page number when a page number is clicked.
 */
    const handleOnClickPageNumber = (pageNum) => {
        const params = Object.fromEntries(searchParams)
        setSearchParams({ ...params, page: pageNum.selected + 1 })
    }

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
`useEffect` hook is used to call the `handleFetch` function when the component mounts or when the
`handleFetch` function changes. This ensures that the auction data is fetched and updated
whenever there is a change in the search parameters or when the component mounts. */
    useEffect(()=>{
        handleFetch()
    }, [handleFetch])

    return (
        <div className="sm:mx-10 mx-5 sm:my-8 mt-8 mb-20 ">
            {/* { modalOpen.isModalOpen && <AcceptBidderModal modalOpen={modalOpen} setModalOpen={setModalOpen} 
                approveBidderHandler={approveBidderHandler} loader={loader} />} */}
            {isOpen && <ModalBidder isOpen={isOpen} setIsOpen={setIsOpen} handleExport={handleExport} 
                loader={loader} />}
            {message!=="" && <ToastMeassage message={message}  />}
            <TableHeader
                bidders={bidders}
                profile={profile}
                message={message}
                loading={loading}
                biddersInfo={biddersInfo}
                setMessage={setMessage}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                handleSearch={handleSearch} 
                isFilterApplied={isFilterApplied}
                setIsFilterApplied={setIsFilterApplied}
                handleExport={handleExport}
                setIsOpen={setIsOpen}
                exportHandler={exportHandler}
                
            />
            <div className="pb-5 pt-2 overflow-auto ">
                <TableBody
                    bidders={bidders}
                    handleSort={handleSort}
                    loading={loading}
                    biddersInfo={biddersInfo}
                    isFilterApplied={isFilterApplied}
                    searchParams={searchParams}
                    currentSortColumn={currentSortColumn}
                  
                />
            </div>
            {(biddersInfo.page_number <= biddersInfo.total_buyers) &&
            <Paginate
                totalPageNumber={Math.ceil(biddersInfo.total_buyers/10)}
                selectedPageNumber={Number(searchParams.get('page')) || 1}
                handleOnClickPageNumber={handleOnClickPageNumber} 
            />
            }
        </div>
    )
}
