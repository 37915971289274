/* eslint-disable security/detect-possible-timing-attacks */
import config from './config'
import { useEffect } from 'react';
import './App.css';
import NavigationRoutes from './routes';
import { motion } from 'framer-motion';
import { AwsRum } from 'aws-rum-web';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
if (config.Environment.stage==="prod"){
    let awsRum = null;
    try {
        const config = {
            sessionSampleRate: 1,
            identityPoolId: "eu-west-2:531dfac8-26be-457e-83da-323858bd00e9",
            endpoint: "https://dataplane.rum.eu-west-2.amazonaws.com",
            telemetries: ["performance","errors","http"],
            allowCookies: true,
            enableXRay: false
        };

        const APPLICATION_ID = 'bf6c6c88-d8d1-4c98-9470-6eeec5589d47';
        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = 'eu-west-2';

        awsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
        );
    } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    }
}



function App(props) {
    const navigationPath = NavigationRoutes(props)
    const navigate= useNavigate()
    

    /* The `useEffect` hook in this code is used to perform side effects in a functional component. In
    this case, it is used to check if there is a token parameter in the URL query string. */
    useEffect(()=>{
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const token = params.get('token');
       
        if (token) {
            localStorage.setItem('verify_token', token);
            window.location = '/verify_token';
        }
    },[])
    
    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}>
            {navigationPath}
        </motion.div>
    );
}

export default App;
