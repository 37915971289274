import {React, useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import SetUpAuctionforMobile from './commonLayout/setUpAuctionforMobile'
import SaveDraftModal from './commonLayout/saveDraftModal';
import { selectFontStyle } from '../../../CommonComponent/SelectFontStyle';

export default function AuctionHeader({auctionData, setAuctionData, setEditedAuctionData, handleLogoChange, 
    handleLogoRedirectionChange, handleLogoDelete, errorMessages, addMenuLink, deleteMenuLink,
    addDropdownLink,deleteDropdownLink, newMenuLink, setNewMenuLink, isAddingDropdown, setIsAddingDropdown,
    setMenuModal, menuModal, dropdownLinkError, setDropdownLinkError,startDate,setStartDate,endDate,setEndDate,
    startHour,setStartHour,startHourShow, setStartHourShow,startMin,setStartMin,startMinShow, setStartMinShow,
    endHour,setEndHour,endHourShow,setEndHourShow,endMin,setEndMin,mobileModal, isMobileModal,
    endMinShow,setEndMinShow,timezoneShow,setTimezoneShow,saveDraftModal,setSaveDraftModal,handleCreateAuction, loader,
    menuLinkError, setMenuLinkError, logoLoader,setLogoLoader,imageLoader,setImageLoader, setInvalidDate,setSameTime,invalidDate,
    sameTime}) {
        
    const [isOpen, setIsOpen] = useState(true)
    const path = useLocation();
    const [disableSave, setDisableSave] = useState(false)
    const {id} = useParams()
    const [text, setText]=useState("");

    useEffect(()=>{
        if(auctionData!==undefined && Object.keys(auctionData).length>0){
            setText(auctionData.description);
        }
    },[auctionData && Object.keys(auctionData).length])
    
    useEffect(()=>{
        if(menuLinkError!==undefined){
            if(errorMessages.logoError!=='' || (auctionData.make_your_auction_private==true && auctionData.passcode=="") 
            || errorMessages.logoRedirectionUrlError!=='' 
        || errorMessages.auction_image_error!=='' || menuLinkError.title || menuLinkError.url || invalidDate || sameTime 
        || Number(auctionData.extension_time_between_lots) > 60 || Number(auctionData.extension_time) >1440 ){
                setDisableSave(true)
            }
            else{
                setDisableSave(false)
            }
        }
    },[errorMessages, auctionData, invalidDate, sameTime])

    return (
        <>
            <nav className="h-[68px] md:px-10 px-5 flex border-b border-[#D4D4D8] justify-between overflow-x-hidden 
        sticky top-0 z-[101] bg-[#FFFFFF]" >
                <Link 
                    to={(path.pathname==='/auctions/create/classic' || 
                    path.pathname==='/auctions/create/single-lot' || 
                    path.pathname==='/auctions/create/standalone') ? "/auctions/create" : '/auctions'} 
                    //     (path.pathname===`/auctions/edit/${id}/classic` ||
                    // path.pathname===`/auctions/edit/${id}/single-lot` ||
                    // path.pathname===`/auctions/edit/${id}/standalone`)?`/auctions/edit/${id}`:'/auctions'} 
                    className="flex items-center">
                    <img src="/images/arrow-left-dark.svg"  alt="img" className="cursor-pointer" loading="lazy" />
                    <p className="md:block hidden pl-5 py-5">Auction creator</p>
                </Link>
                {path.pathname!=='/auctions/create'&& path.pathname!==`/auctions/edit/${id}` &&
                <div className='flex justify-end items-center'>
                    <button id="preview-btn"  onClick={()=>setIsOpen((prevState) => !prevState)} 
                        className={`md:hidden block border border-[#282828] rounded-md text-[14px] text-[#282828] 
                leading-normal font-medium px-[20px] py-[12px] ${isOpen ? "w-[110px]" : "w-[165px]"} 
                md:w-[135px] bg-[#FFFFFF] mr-2`}>
                        {!isOpen?"Continue Editing":"Preview"}</button>
                    <button id="save-btn" onClick={()=>setSaveDraftModal(true)} 
                        disabled={((auctionData.status=="Published" || auctionData.status=="Accepting bids") && 
                        (auctionData.title=="" || auctionData.auction_image=="" || auctionData.description=="" )) || disableSave }
                        className=" bg-[#282828] rounded-md text-[14px] text-[#fff] 
                leading-normal font-medium px-[20px] py-[12px] w-[110px] md:w-[135px] disabled:bg-[#A1A1A9]" 
                    >Save</button>
                </div>}
            </nav>  
            {auctionData!==undefined && menuLinkError!==undefined && logoLoader!==undefined && setLogoLoader!==undefined &&
            imageLoader!==undefined && setImageLoader!==undefined && invalidDate!==undefined && setSameTime!==undefined &&
            <>
                <SetUpAuctionforMobile setIsOpen={setIsOpen} isOpen={isOpen} auctionData={auctionData} 
                    text={text} setText={setText}
                    setEditedAuctionData={setEditedAuctionData}
                    setAuctionData={setAuctionData}
                    handleLogoChange={handleLogoChange}
                    handleLogoRedirectionChange={handleLogoRedirectionChange}
                    handleLogoDelete={handleLogoDelete}
                    errorMessages={errorMessages}
                    addMenuLink={addMenuLink}
                    deleteMenuLink={deleteMenuLink}
                    addDropdownLink={addDropdownLink}
                    deleteDropdownLink={deleteDropdownLink}
                    newMenuLink={newMenuLink}
                    setNewMenuLink={setNewMenuLink}
                    isAddingDropdown={isAddingDropdown} 
                    setIsAddingDropdown={setIsAddingDropdown}
                    setMenuModal={setMenuModal}
                    menuModal={menuModal}
                    dropdownLinkError={dropdownLinkError} 
                    setDropdownLinkError={setDropdownLinkError}
                    startDate={startDate} 
                    setStartDate={setStartDate}
                    endDate={endDate} 
                    setEndDate={setEndDate}
                    startHour={startHour}
                    setStartHour={setStartHour}
                    startHourShow={startHourShow} 
                    setStartHourShow={setStartHourShow}
                    startMin={startMin}
                    setStartMin={setStartMin}
                    startMinShow={startMinShow} 
                    setStartMinShow={setStartMinShow}
                    endHour={endHour}
                    setEndHour={setEndHour}
                    endHourShow={endHourShow}
                    setEndHourShow={setEndHourShow}
                    endMin={endMin}
                    setEndMin={setEndMin}
                    endMinShow={endMinShow}
                    setEndMinShow={setEndMinShow}
                    timezoneShow={timezoneShow}
                    setTimezoneShow={setTimezoneShow}
                    menuLinkError={menuLinkError}
                    setMenuLinkError={setMenuLinkError}
                    logoLoader={logoLoader}
                    setLogoLoader={setLogoLoader}
                    imageLoader={imageLoader}
                    setImageLoader={setImageLoader}
                    setInvalidDate={setInvalidDate}
                    setSameTime={setSameTime}
                    mobileModal ={mobileModal}
                    isMobileModal={isMobileModal}
                />
                <SaveDraftModal saveDraftModal={saveDraftModal}
                    setSaveDraftModal={setSaveDraftModal} auctionData={auctionData}
                    handleCreateAuction={handleCreateAuction}
                    loader={loader}/>
            </>
            }
        </>  
    )
}
