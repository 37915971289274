/* eslint-disable max-len */
import React, { useState } from 'react'
import { Menu } from "@headlessui/react";
import { motion } from 'framer-motion'
import {useNavigate, useParams} from 'react-router-dom';
import { currencySymbolMapping } from '../../../../CommonComponent/staticValues';
import { PaymentInfoModal } from './paymentInfoModal';


export default function SaleTableBody({sales, auctionData, salesInfo, searchParam, onChangePaymentInfo}) {
    const navigate = useNavigate()
    const {id,uid}=useParams();
    const [isPaymentInfoModalOpen, setIsPaymentInfoModalOpen] = useState(false)

    const dateConverter = (timestamp)=>{
        const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    
        // Format the date as "dd MMM yyyy"
        const formattedDate = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        }).format(date);
        return formattedDate;
    }

    return (
        <>
            {sales.map((sale,index)=>(
                <motion.tr className="border-b  border-[#DEDEDE] table-row h-14 " 
                    key={index}
                    id= "list-items"
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.002 }}
                >
                    <td className=" text-[#343434]  px-4 py-3 text-[14px] leading-normal font-medium text-center">
                        {sale.order_number}
                    </td>
                    <td className=" text-[#343434]  px-4 py-3 text-[14px] leading-normal font-medium text-center">
                        {sale.name==undefined?'-':sale.name}
                    </td>
                    <td className=" text-[#343434]  px-4 py-3 text-[14px] leading-normal font-medium text-center">
                        {sale.auction_title}
                    </td>
                    <td className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium">
                        {dateConverter(sale.created_at)}
                    </td>
                    <td className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium">
                        {currencySymbolMapping[sale.currency.toUpperCase()]}{sale.amount}
                    </td>
                    <td className=" text-[#343434] px-4 py-3 text-[14px] text-center leading-normal font-medium">
                        {sale.payment}
                    </td>
                    <td className='text-center'>
                        <span className={`${sale.payment_status=='Paid'?'text-[#489892] bg-[#A8D9C8]':
                            sale.payment_status=='Unpaid'?'text-[#B98B47] bg-[#E3C79D]':
                                'text-[#343434] bg-[#DDD1D5]'} px-4 py-3 text-[14px] text-center leading-normal font-medium 
                                min-w-[96px] rounded-[42px]`}>
                            {sale.payment_status}
                        </span>
                    </td>
                    <td className=" sm:relative  sm:bg-transparent bg-[#fff]  sticky top-0 right-0">
                        <div className=" sm:px-4 px-2 h-fit py-[43px] sm:shadow-none shadow-bs7 ">
                            <span className="flex justify-end ">
                                <Menu as="div" className="relative" >
                                    <Menu.Button>
                                        <img src="/images/three-dots-icon-black.svg" alt="img" 
                                            className="cursor-pointer three-dots-icon" type="button"
                                            id="dropdownMenuButton11"/>
                                    </Menu.Button>
                                    <Menu.Items>
                                        <div className="dropdown-cont absolute overflow-hidden rounded-[10px] float-left 
                                                m-0 w-[150px] py-2 min-w-max right-0 z-[50] list-none 
                                                border-none bg-white bg-clip-padding text-left text-base shadow-bs3 -top-[32px]">
                                            <Menu.Item>
                                                <button type='button' data-testid="view_order"
                                                    onClick={()=>navigate(`/auctions/${id}/${uid}/order-details/${sale._id}`)}
                                                    className="block w-full whitespace-nowrap bg-transparent pl-5 pr-7 py-2 
                                                    text-[12px] font-medium text-[#343434] text-left"
                                                >View</button>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <button type='button' data-testid="change_payment_info"
                                                    onClick={() => setIsPaymentInfoModalOpen(true)}
                                                    className="block w-full whitespace-nowrap bg-transparent pl-5 pr-7 py-2 
                                                    text-[12px] font-medium text-[#343434] cursor-pointer"
                                                >Change payment info</button>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <a 
                                                    className="block w-full whitespace-nowrap bg-transparent pl-5 pr-7 py-2 
                                                    text-[12px] font-medium text-[#343434] cursor-pointer"
                                                >Send payment request</a>
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Menu>
                            </span>  
                        </div> 
                    </td>
                    <PaymentInfoModal
                        isOpen={isPaymentInfoModalOpen}
                        setIsOpen={setIsPaymentInfoModalOpen}
                        handleOK={(data) => onChangePaymentInfo(sale._id, data)}
                        loader={false}
                        paymentStatus={sale.payment_status}
                        paymentType={sale.payment}
                    />
                </motion.tr>
            ))}
        </>
    )
}
