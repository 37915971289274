export default function Insights(){
    return(
        <div
            className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
            id="tabs-insights3"
            role="tabpanel"
            aria-labelledby="tabs-insights-tab3">
            Tab 3 content button version
        </div>
    )
}