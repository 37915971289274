import React from 'react'
import LiveTiming from './liveTiming'
import sanitizeHtml from 'sanitize-html';
import { formatDate } from '../../../../CommonComponent/dateConverter';
import { currencySymbolMapping } from '../../../../CommonComponent/staticValues';
import { selectFontStyle } from '../../../../CommonComponent/SelectFontStyle';

export default function AuctionDetails({auctionData,startHour,startMin,endHour,endMin,endDate,startDate}) {
    const extend= `${auctionData?.description?.length>200 ? 'Read more' : ''}`
    const htmlContent =`${auctionData?.description?.slice(0, 200)} ${extend}`
    
    function renderHTML(htmlString) {
        const sanitizedHTML = sanitizeHtml(htmlString);
        return { __html: sanitizedHTML };
    }
    return (
        <div className="llg:w-[50%] llg:mr-8">
            <p className="text-[8px] font-medium leading-normal text-center " 
                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                {`${startDate ? formatDate(startDate) : '12 Jul 2023'} / ${
                    startHour!=='' ? String(startHour).padStart(2, '0') : '08'
                }:${
                    startMin !== '' ? String(startMin).padStart(2, '0') : '00'
                } ${auctionData.time_zone !== '' ? auctionData.time_zone.split(' - ')[0] : 'BST'} - ${
                    endDate!=='' ? formatDate(endDate) : '22 Jul 2023'
                } / ${
                    endHour!=='' ? String(endHour).padStart(2, '0') : '20'
                }:${
                    endMin !== '' ? String(endMin).padStart(2, '0') : '00'
                } ${auctionData.time_zone !== '' ? auctionData.time_zone.split(' - ')[0] : 'BST'}`}
            </p>
            <p id='updated-auction-title' className="text-[23px] pt-2  leading-normal text-center"
                style={{fontFamily: selectFontStyle(auctionData.font['hearder_font'])}}>
                {auctionData.title===''? 'Auction Title goes here':auctionData.title}</p>
            <LiveTiming auctionData={auctionData}/>
            {/* <!-- paragraph --> */}
            <div className="flex  text-center mx-16 items-center justify-center"
                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                {auctionData.description===''?
                    <p id='updated_auction-description' className="pt-6 text-[9px] text-center leading-normal ">
                        Description goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse 
                        fermentum mollis neque vel posuere. Consectetur adipiscing elit. Suspendisse fermentum mollis neque vel. 
                        <br/> <a href="#" className="underline">Read more</a></p>:
                    <div id='updated_auction-description' className="pt-6 text-[9px] text-center leading-normal break-normal" 
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}
                        dangerouslySetInnerHTML={renderHTML(htmlContent)}/>}
            </div>
            {/* <!-- clock content --> */}
            <div className="flex justify-between pt-3 pb-4 border-[#E6E6E6] border-b  items-center">
                <div className="px-[20px] text-center" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                    <img src="/images/clock-icon.svg" alt="clockicon" className="px-[20px]" loading="lazy" />
                    <p className="text-[8px] pt-1 text-center font-medium leading-normal"
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Timed Auction</p>
                </div>
                <div className="px-[20px] text-center" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                    <p className="text-[18px]  font-medium leading-normal">0</p>
                    <p className="text-[8px] text-center font-medium leading-normal"
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>No. of lots</p>
                </div>
                <div className="px-[20px] text-center" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                    <p className="text-[18px] leading-normal">{currencySymbolMapping[auctionData.currency]}</p>
                    <p className="text-[8px] text-center font-medium leading-normal" 
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Currency</p>
                </div>
            </div>
            {/* <!-- join auction content --> */}
            <div className="flex pt-[14px] justify-between pb-4 border-[#E6E6E6] border-b ">
                <div >
                    <p className="text-[11px] leading-normal" 
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>Join the auction</p>
                    <p className="text-[8px] leading-normal">
                        You must register to bid in this auction</p>
                </div>
                <div>
                    <button id="register" 
                        className="cursor-default bg-[#282828] rounded-md text-[9px] 
                    text-[#fff] leading-normal  px-[10px] py-[8px] w-[121px] md:w-[155px]"
                        style={{backgroundColor: auctionData.buttons['background_color'], 
                            color: auctionData.buttons['text_color'],
                            fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                        Register for the auction</button>
                </div>
            </div>
        </div>    
    )
}
