/* eslint-disable max-len */
import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Oval } from  'react-loader-spinner'

export default function ModalBidder({isOpen, setIsOpen, handleExport, loader}) {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[99]" onClose={()=>setIsOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-80" />
                </Transition.Child>
                <div className="fixed left-0 top-0 z-[1055] h-full w-full 
                    overflow-y-auto overflow-x-hidden outline-none">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full transform overflow-hidden rounded-[10px]
                                    bg-white text-left align-middle shadow-xl transition-all 
                                    min-[640px]:mx-auto min-[640px]:mt-7 min-[640px]:min-h-[calc(100%-3.5rem)] 
                                    min-[640px]:max-w-[520px]">
                                <button
                                    type="button" id='close-icon'
                                    className="box-content rounded-none border-none absolute top-4 right-4 z-10"
                                    data-te-modal-dismiss
                                    aria-label="Close" onClick={()=>setIsOpen(false)}>
                                    <img src="/images/CloseButton-modalcontent.svg" alt="img" loading="lazy"/>
                                </button>
                                <div className="relative pt-[50px] pb-[35px]">
                                    <p className="px-4 text-center pb-[12px] leading-normal text-[16px] 
                                    text-[#343434] font-medium" id='Auction Lot Data'>You are about to export the auction Bidders data.</p>
                                    <p className='pr-[71px] pl-[61px] text-center text-[14px] pb-[27px] text-[#A1A1A9] font-medium'>By clicking ‘Export’, you acknowledge your responsibility for the safe handling of this data and agree to comply with all relevant laws and regulations as well as the Indy.auction terms of use.</p>
                                    <div className="flex justify-center">
                                        <button className="px-[31px] py-[12px] border border-[#A1A1A9] 
                                            bg-[#fffff]  text-[#282828] leading-normal 
                                            font-medium text-[14px] rounded-[6px]" onClick={()=>setIsOpen(false)}>
                                            Cancel
                                        </button>
                                        <div className='ml-[20px]'>
                                            <button id='export-btn' 
                                                disabled={loader}
                                                className="px-[31px] py-[12px] bg-[#282828]  text-[#FFFFFF] disabled:bg-[#D4D4D8] disabled:text-[hsl(240,4%,65%)]
                                                leading-normal font-medium text-[14px] rounded-[6px] flex justify-center" 
                                                onClick={()=>{ handleExport()}}>
                                                Export
                                                {loader&& 
                                                <Oval
                                                    height={20}
                                                    width={50}
                                                    color="#FFFFFF"
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#000000"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}
                                                />
                                                }
                                            </button>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}