/* eslint-disable max-len */
import React, { useState } from 'react'
import Sidebar from './sidebar'
import Topbar from './topbar'
import { Outlet } from 'react-router-dom'
import MobileFooter from './mobileFooter'

export default function Layout({isTopbarIsOpen, setIsTopBarIsOpen}) {

    return (
        <div className="relative sm:flex m-0 p-0">
            <Topbar isTopbarIsOpen={isTopbarIsOpen} setIsTopBarIsOpen={setIsTopBarIsOpen} />
            <div className={`lg:w-104 lg:ml-62 md:ml-[200px] md:w-105 w-full ${isTopbarIsOpen ? 'main' : 'main open'}`}>
                <Sidebar />
                <Outlet />
                <MobileFooter/>
            </div>
        </div>
    )
}

