import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux';
import { Amplify, Auth } from 'aws-amplify';
import config from './config';
import ErrorBoundary from './ErrorBoundary';


Amplify.configure({
    Auth: {
    // mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
            domain: process.env.REACT_APP_SELLER_COGNITO_USERPOOL_DOMAIN,
            scope: [
                'email', 'aws.cognito.signin.user.admin', 'profile', 'openid'
            ],
            redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
            redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
            attributes: { // I tried this but it doesn't change anything
                username: 'email',
            },
        },
    },
    Storage: {
        AWSS3: {
            bucket: config.Storage.bucket,
            region: config.Storage.region,
        },
    },
});

// Auth.currentAuthenticatedUser()
//     .then(user => {
//         console.log('User:', user);
//     // User is signed in
//     })
//     .catch(err => {
//         console.log(err, err.name, err.message, 'j')
//         localStorage.clear()
//         Auth.signOut()
//         if (err.name === 'NotAuthorizedException' && err.message === 'User is disabled') {
//             console.error('User is disabled');
//             localStorage.clear()
//             Auth.signOut()
//         } else {
//             console.error('No current user:', err);
//             localStorage.clear()
//             Auth.signOut()
//         }
//     });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
