
/* eslint-disable max-len */
export const currencyValues = [
    { id: 1, name: 'GBP' },
    { id: 2, name: 'USD' },
    { id: 3, name: 'EUR' },
    { id: 4, name: 'HKD' },
    { id: 5, name: 'JPY' },
    { id: 6, name: 'CHF' },
    { id: 7, name: 'SGD' },
    { id: 8, name: 'AUD' },
    { id: 9, name: 'CAD' },
    { id: 10, name: 'INR' },
]
export  const currencySymbolMapping = {
    'GBP': '£',
    'USD': '$',
    'EUR': '€',
    'HKD': 'HK$',
    'JPY': '¥',
    'CHF': 'Fr',
    'SGD': 'S$',
    'AUD': 'A$',
    'CAD': 'C$',
    'INR': '₹',
};

export const timeZoneMap = {
    'UTC - Coordinated Universal Time': 'Etc/UTC',
    'GMT - Greenwich Mean Time': 'Etc/GMT',
    'BST - British Summer Time': 'Europe/London',
    'CET - Central European Time': 'Europe/Paris',
    'IST - India Standard Time': 'Asia/Kolkata',
    'CST - China Standard Time': 'Asia/Shanghai',
    'JST - Japan Standard Time': 'Asia/Tokyo',
    'AEST - Australian Eastern Standard Time': 'Australia/Sydney',
    'NZST - New Zealand Standard Time': 'Pacific/Auckland',
    'PST - Pacific Standard Time(US)': 'America/Los_Angeles',
    'MST - Mountain Standard Time (US)': 'America/Denver',
    'CST - Central Standard Time (US)': 'America/Chicago',
    'EST - Eastern Standard Time (US)': 'America/New_York'
};

export const timezoneValues = [
    { id: 1, name: 'UTC - Coordinated Universal Time' },
    { id: 2, name: 'GMT - Greenwich Mean Time' },
    { id: 3, name: 'BST - British Summer Time' },
    { id: 4, name: 'CET - Central European Time' },
    { id: 5, name: 'IST - India Standard Time' },
    { id: 6, name: 'CST - China Standard Time' },
    { id: 7, name: 'JST - Japan Standard Time' },
    { id: 8, name: 'AEST - Australian Eastern Standard Time' },
    { id: 9, name: 'NZST - New Zealand Standard Time' },
    { id: 10, name: 'PST - Pacific Standard Time(US)' },
    { id: 11, name: 'MST - Mountain Standard Time (US)' },
    { id: 12, name: 'CST - Central Standard Time (US)' },
    { id: 13, name: 'EST - Eastern Standard Time (US)' },
]

export const timeDifference = {
    'UTC - Coordinated Universal Time' : '' ,
    'GMT - Greenwich Mean Time' : '' ,
    'BST - British Summer Time' : '(UTC+1)' ,
    'CET - Central European Time' : '(UTC+1)' ,
    'IST - India Standard Time' : '(UTC+5:30)' ,
    'CST - China Standard Time' : '(UTC+8)' ,
    'JST - Japan Standard Time' : '(UTC+9)' ,
    'AEST - Australian Eastern Standard Time' : '(UTC+11)' ,
    'NZST - New Zealand Standard Time' : '(UTC+13)' ,
    'PST - Pacific Standard Time(US)' : '(UTC-8)' ,
    'MST - Mountain Standard Time (US)' : '(UTC-7)' ,
    'CST - Central Standard Time (US)' : '(UTC-6)' ,
    'EST - Eastern Standard Time (US)' : '(UTC-5)' 
}

export const extenstionType = [
    { id: 1, name: 'All Lots' },
    { id: 2, name: 'Cascade' },
    { id: 3, name: 'Individual Lots' }
]
export const registrationType = [
    { id: 1, name: 'Email only' },
    { id: 2, name: 'Credit (bank) card validation' },
    { id: 3, name: 'Manual approval' }
]
export const buyerFees = [
    { id: 1, name: 'No additional fees (default)'},
    { id: 2, name: 'Add percentage' },
    { id: 3, name: 'Add fixed fee' }
]
export const FontList = [
    { id: 1, name: 'Arimo'},
    { id: 2, name: 'Comic Neue' },
    { id: 3, name: 'Courier Prime' },
    { id: 4, name: 'Gothic A1' },
    { id: 5, name: 'Lato' },
    { id: 6, name: 'Montserrat' },
    { id: 7, name: 'Open Sans' },
    { id: 8, name: 'Roboto' },
    { id: 9, name: 'Tinos' },
    { id: 10, name: 'Inter'}

]


export const emailTemplateList = [
    { 
        type: "Buyer OTP email",
        subject: "Your 6 digit code for Indy.auction",
        name: '-OTP-VALIDATION',
        default: 'buyer-default-otp-template',
        template: `
        <p style="text-align: center; font-size: 22px !important">Thank you for creating an auction account with <span style="font-size: 22px !important" contenteditable="false">*|seller_name|*</span>.</p>
        <p style="text-align: center; font-size: 18px !important"><span contenteditable="false" style="font-size: 18px !important">Please verify your email address using the 6 digit code below.</span></p>
        <div style="text-align: center; 
           font-size: 56px;
           margin: 20px auto;"
           contenteditable="false" ><span contenteditable="false">*|otp|*</span></div>
        <p style="text-align: center"><span style="font-size: 18px !important;" >This code is valid for 10 minutes.</span></p>
        <p style="text-align: center; font-size: 14px !important">If you did not register this address, please contact support@indy.auction</p>
      `
    },
   
    {
        type: "Buyer bidding paddle",
        subject: "Let the bidding begin",
        name: '-PADDLE-GENERATION',
        default: 'buyer_default_paddle_template',
        template: `
        <p>Dear <span class="noneditable" contentEditable="false" style="font-size: 14px;">*|user_first_name|*</span>,</p>
<div style="height:1px;"></div>
<p>Congratulations, <span style="font-size: 14px;">you are now registered to bid in the auction.</span></p>
<div class="se-component se-image-container __se__float-">
    <figure style="display: flex;">
        <img src="/images/imagePrototype.svg" data-align="none" alt="placeholder">
        <figcaption>
            <span class="auction-title">*|Auction_title|*</span>
            </br>Auction ends: *|auction_end_date|*
            </br>
            </br>
            <br/><br/>
            <a href="*|domainURL|*" style="margin-top: 20px">View auction</a>
            <br/><br/>
        </figcaption>
    </figure>
</div>
<div style="height:1px;"></div>
<p>Your paddle number is attached. It is unique to you and this auction.</p>
<div style="height:1px;"></div>
<div style="background-color: rgba(217, 217, 217, 1); color: rgba(161, 161, 169, 1); font-size: 60px; border-radius: 6px; margin: 12px auto; text-align: center; padding: 40px; width: fit-content;">*|paddle|*</div>
<div style="height:1px;"></div>
<p>For any questions, please contact <span style="font-size: 14px;">*|seller_email|*</span>.</p>
<br/>
<p>All our best,</p>
<p>
    <span style="font-size: 14px;">*|Seller_name|*</span>
    <div style="height:1px;"></div>
</p>

     `
    },
    {
        type: "Buyer Max Bid Confirmation",
        subject: "Max Bid: *|lot_number|*, *|lot_title|*",
        name: '-WINNING-EMAIL',
        default:'bid_notification_dev',
    },
    {
        type: "Buyer Outbid notification",
        subject: "Outbid Notification: *|lot_number|*, *|lot_title|*",
        name: '-OUTBID-EMAIL',
        default:'outbid_notification_dev',
        template:` `
    },
    {
        type: "Buyer payment request (automated)",
        subject: "Congratulations | Payment Request",
        name: '-CONGRATULATION-EMAIL',
        default:'default_congratulations_email',
        template:''
    },
    // {
    //     type: "Buyer payment request (manually triggered)",
    //     subject: "[Congratulations | Payment Request]",
    //     name: ''
    // },
    {
        type: "Buyer payment receipt",
        subject: "Thank You | Payment Receipt",
        name: '-PAYMENT-RECEIPT',
        default:'default_payment-receipt',
        template:''
    },
    // {
    //     type: "Buyer password reset",
    //     subject: "[Reset your auction password]",
    //     name: ''
    // },
]
export const initialFAQs = [
    {
        question: 'How do I register for an auction?',
        answer:
        'Click on the "Register for the Auction" button on the auction page. Once you confirm your intent to register for the auction, you\'ll be provided with your paddle number which will also be emailed to you. Once the auction is live, you can start bidding in the auction. Please note that to validate your intent to bid for certain auctions, you might be required to enter your credit or debit card information or be requested for documents such as a photo ID or proof of funds.',
    },
    {
        question: 'What is a maximum bid?',
        answer:
        'The maximum bid (or max bid) is the highest amount you are willing to pay for an auction lot. When you set your max bid, the auction software automatically places bids on your behalf at the next available increments if other competitive bids come in to keep you as the highest bidder up to your maximum bid and never any further. If no one else bids higher, the max bidder wins the auction at the lowest possible price, which is the amount of the second-highest bid plus the minimum increment. Max bids can be advantageous for bidders who can\'t constantly monitor an auction or who want to avoid bidding wars. By placing a max bid, they can set a limit on how much they\'re willing to pay and let the system do the rest.',
    },
];
export const generateinitialTermsAndCondition = (businessName, email) => `
<div>
    <p><strong>These terms were last updated on: ${new Date().toLocaleDateString()}</strong></p>
    <p>These terms and conditions may have changed since you last participated in, or purchased items at an auction, 
        please therefore ensure that you have read and understood these terms and conditions before registering to bid at, 
        or purchase at, an auction.</p>
    <p><br></p>
    <p><strong>Where to find information about us, our auctions and our listed items.</strong></p>
    <p>Before you participate in an auction, you can find everything you need to know about us ${businessName ?? ''}, 
        our auctions, and the items we have listed for sale at an auction on our auction page, including details of who we are. 
        For more information you can contact us at ${email ?? ''}.
    </p>
</div>`