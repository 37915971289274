/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import config from '../../../config'
import sanitizeHtml from 'sanitize-html';
import { currencySymbolMapping, timeDifference, timeZoneMap } from '../../../CommonComponent/staticValues';
import { useOutletContext } from "react-router-dom";
import OverViewShimmer from './overview/overViewShimmer';

export default function AuctionOverView() {
    const [startDate,setStartDate] = useState('')
    const [startMin,setStartMin] = useState('')
    const [endDate,setEndDate] = useState('')
    const [endMin,setEndMin] = useState('')
    const outletContext = useOutletContext();
    const [isAuctionInvalid, setIsAuctionInvalid] = useState(false)

    const { auctionData,lots,loading } = outletContext;

    function renderHTML(htmlString) {
    /**
     * The function "renderHTML" takes an HTML string, sanitizes it, and returns an object with a
     * property "__html" containing the sanitized HTML.
     * @returns An object with a property named "__html" that contains the sanitized HTML string.
     */
        const sanitizedHTML = sanitizeHtml(htmlString);
        return { __html: sanitizedHTML };
    }

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to format the start and end dates of an auction and set them in the
    component's state variables (`startDate`, `startMin`, `endDate`, `endMin`). */
    // useEffect(()=>{
    //     const options = {
    //         year: "numeric",
    //         month: "short",
    //         day: "2-digit",
    //         hour: "numeric",
    //         minute: "2-digit",
    //         timeZone: timeZoneMap[auctionData.time_zone ? auctionData.time_zone : 'Etc/GMT']
    //     };
    //     const formatter = new Intl.DateTimeFormat("en-GB", options);
        
    //     if(auctionData.start_date==""){
    //         setStartDate("");
    //     }else{
    //         const start_date = new Date(auctionData.start_date);
    //         const formattedDate = formatter.format(start_date);
    //         const [date1, time] = formattedDate.split(", ");
    //         setStartDate(date1.includes('1970')?'':date1)
    //         setStartMin(time)
    //     }
    //     if(auctionData.end_date==""){
    //         setEndDate("");
    //     }else{
    //         const end_date = new Date(auctionData.end_date)
    //         const formattedEndDate = formatter.format(end_date);
    //         const [date2, time1] = formattedEndDate.split(", ");
    //         setEndDate(date2.includes('1970')?'':date2)
    //         setEndMin(time1)
    //     }
    // },[])

    const convertTimestampToDayMonthYear = (timestamp, time_zone) => {

        if(timestamp==0){
            return '-';
        }

        const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            timeZone: timeZoneMap[time_zone ? time_zone : 'Etc/GMT']
        };
        const formatter = new Intl.DateTimeFormat("en-GB", options);

        const start_date = new Date(timestamp);
        const formattedDate = formatter.format(start_date);
        const [date1, time] = formattedDate.includes('at') ? formattedDate.split("at ") : formattedDate.split(", ");
        if(date1.includes('1970') || date1==""){
            return '-'
        }
    
        return `${date1} / ${time} ${time_zone === '' ? 'GMT' : time_zone.split(' - ')[0]} ${timeDifference[time_zone === '' ? 'GMT - Greenwich Mean Time' : time_zone]}`;
    };

    return (
        <>
            {(loading || Object.keys(auctionData).length==0) ? <OverViewShimmer /> : <div
                className="pb-10 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                id="tabs-home3"
                role="tabpanel"
                    
                aria-labelledby="tabs-home-tab3">
                {/* <!-- overview content --> */}
                <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                    <p className="py-5 border-b border-[#CECECE] pl-[35px] px-4 text-[16px] font-medium 
                    leading-normal text-[#343434]">Information</p>
                    {/* <!-- information content --> */}
                    <div className="flex flex-wrap mt-8 mb-[15px] w-full">
                        <div className=" xxs:w-[30%] w-full pl-[35px] mb-10">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Thumbnail</p>
                            {auctionData.auction_image!==''?
                                <img 
                                    src={`${config.Storage.CDN}${auctionData.auction_image}`} 
                                    className="py-2 w-[80px]  h-[77px]" alt="img" />
                                :
                                <p className='text-[16px] font-medium leading-normal text-[#343434]'>-</p>
                            }
                        </div>
                        <div className=" xs:w-[70%] w-full md:pr-[54px] pr-5 xs:pl-0 pl-[35px]  mb-10">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Description</p>
                            <div className="">
                                {auctionData.description===''?
                                    <p className="text-[16px] font-medium leading-normal text-[#343434]">-</p>:
                                    <>
                                        <div data-testid='auction-description' id="updated_auction-description"  className="text-[16px] font-medium leading-normal text-[#343434] break-all"
                                            dangerouslySetInnerHTML={renderHTML(auctionData.description)}/>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="md:w-[30%] xs:w-[30%] w-full pl-[35px] mb-10">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Currency</p>
                            <p className="text-[16px] font-medium break-words leading-normal text-[#343434]">{auctionData.currency}</p>
                        </div>
                        <div className="md:w-[33%] xs:w-[50%] w-full xs:pl-0 pl-[35px]  mb-10">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Start Date / Time</p>
                            <p className="text-[16px] font-medium leading-normal text-[#343434]"> {convertTimestampToDayMonthYear(auctionData.start_date, auctionData.time_zone)}</p>
                        </div>
                        <div className="md:w-[33%]  w-full pl-[35px] md:pl-0 mb-10">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">End Date / Time</p>
                            <p className="text-[16px] font-medium leading-normal text-[#343434]">  {convertTimestampToDayMonthYear(auctionData.end_date, auctionData.time_zone)}</p>
                        </div>
                    </div>
                </div>
                {/* <!-- rules content --> */}
                <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                    <p className="py-5 border-b border-[#CECECE] pl-[35px] text-[16px] font-medium leading-normal 
                    text-[#343434]">Rules</p>
                    <div className="flex flex-wrap mt-8 mb-[10px] w-full px-[35px]">
                        <div className="md:w-[30%] xxs:w-[50%] w-full mb-[36px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Type</p>
                            <p className="text-[16px]  font-medium leading-normal text-[#343434]">Timed Auction</p>
                        </div>
                        <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Extension type</p>
                            <p className="text-[16px] font-medium leading-normal text-[#343434]">{auctionData.extension_type}</p>
                        </div>
                        <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Extension time (mins)</p>
                            <p className="text-[16px]  font-medium leading-normal text-[#343434]">{auctionData.extension_time===''?'-':auctionData.extension_time}</p>
                        </div>
                        <div className="md:w-[30%] xxs:w-[50%] w-full mb-[36px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Time between lots</p>
                            <p className="text-[16px] font-medium leading-normal text-[#343434]">{auctionData.extension_time_between_lots===''?'-':auctionData.extension_time_between_lots}</p>
                        </div>
                        <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Registration type</p>
                            <p className="text-[16px]  font-medium leading-normal text-[#343434]">{auctionData.registration_type}</p>
                        </div>
                        <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Buyer fees</p>
                            <p className="text-[16px] font-medium leading-normal text-[#343434]">{auctionData.add_buyer_fees.includes('No additional fees') ? auctionData.add_buyer_fees :
                                ( auctionData.add_buyer_fees=="Add percentage" ? (auctionData.percentage!==""  ? auctionData.percentage+'%' : '-' ) : (auctionData.fees=="" ? '-' : currencySymbolMapping[auctionData.currency]+auctionData.fees)  ) 
                                // (auctionData.fees!=='' ?currencySymbolMapping[auctionData.currency]+auctionData.fees:(auctionData.percentage!==''?auctionData.percentage+'%':auctionData.add_buyer_fees)) 
                            }</p>
                        </div>
                    </div>
                </div>
                {/* <!-- privacy content --> */}
                <div className="mt-[25px]  rounded-[8px] border-[#DEDEDE] border">
                    <p className="py-5 border-b border-[#CECECE] pl-[35px] text-[16px] font-medium leading-normal
                     text-[#343434]">Privacy</p>
                    <div className="flex flex-wrap mt-8 mb-[10px]  w-full px-[35px]">
                        <div className="md:w-[30%] xxs:w-[50%] w-full mb-[36px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Type</p>
                            <p className="text-[16px]  font-medium leading-normal text-[#343434]">{auctionData.make_your_auction_private===false ?'Public':'Private'}</p>
                        </div>
                        <div className="md:w-[33%] xxs:w-[50%] w-full mb-[36px]">
                            <p className="text-[10px] font-medium leading-normal text-[#343434]">Passcode</p>
                            <p className="text-[16px] font-medium leading-normal text-[#343434]">{auctionData.passcode===''?'-':auctionData.passcode}</p>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
