/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../../../CommonComponent/outsideClick';

export default function MenuLinkModal({
    menuModal,setMenuModal,newMenuLink,setNewMenuLink,addMenuLink,isAddingDropdown,
    setIsAddingDropdown,deleteDropdownLink,addDropdownLink,dropdownLinkError, setDropdownLinkError,
    menuLinkError, setMenuLinkError, auctionData,isMobileModal,
    startDate,setStartDate,endDate,setEndDate,startHour,setStartHour,startHourShow, 
    setStartHourShow,startMin,setStartMin,startMinShow, setStartMinShow,endHour,setEndHour,endHourShow,setEndHourShow,endMin,setEndMin,
    endMinShow,setEndMinShow,timezoneShow,setTimezoneShow, outsideClickRef
}) {
    // const outsideClickRef = useRef();
    const [disableCreateButton,setDisableCreateButton] = useState(false)
    const titleRegex = /^(?!\s).*$/; // Don't allow spaces in the beginning
    const regex= '^(https?://|www\.)[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
    const urlRegex = new RegExp(regex);

    
    // useOnClickOutside(outsideClickRef, () => {
    //     setMenuModal(false);

    //     document.body.classList.remove('modal-open')
    //     isMobileModal(false)
    // });


    /* The above code is using the useEffect hook in React to check if certain conditions are met in
    order to enable or disable a create button. It checks if the title and link_url properties of
    newMenuLink are empty strings or if there are any errors in the menuLinkError object. If any of
    these conditions are true, the create button is disabled. */
    useEffect(()=>{
        if(auctionData.faq.length===0 || newMenuLink.title==='' || (isAddingDropdown===false && newMenuLink.link_url==='') || menuLinkError.title===true || (isAddingDropdown===false && menuLinkError.link_url===true)){
            setDisableCreateButton(true)
        }
        else{
            if(isAddingDropdown===true){
                if((newMenuLink.dropdown.some(obj => Object.values(obj).includes(''))) || (dropdownLinkError.some(obj => Object.values(obj).includes(true)))){
                    setDisableCreateButton(true)
                }
                else{
                    setDisableCreateButton(false)
                }
            }
            else if(isAddingDropdown===false){
                setDisableCreateButton(false)
            }
        }
    },[newMenuLink,isAddingDropdown, auctionData])

    /**
     * The function `handleMenuLinkChange` is used to handle changes in menu link inputs and update the
     * state accordingly.
     */
    const handleMenuLinkChange = (e, key) => {
        e.preventDefault();
        const value = e.target.value;

        if(!titleRegex.test(value)) {
            return;
        }
        
        if(value===''){
            setMenuLinkError((prevErrors) => ({
                ...prevErrors,
                [key]: false,
            }));
        }
        else{
            const isValid = key === 'link_url' ? urlRegex.test(value) : value;
            setMenuLinkError((prevErrors) => ({
                ...prevErrors,
                [key]: !isValid,
            }));
        }
        setNewMenuLink({ ...newMenuLink, [key]: key === 'link_url'? value.trim(): value });
    };

    /**
     * The function `handleDropdownChange` is used to handle changes in a dropdown menu and update the
     * state accordingly.
     */
    const handleDropdownChange = (dropdownIndex, field, e) => {
        e.preventDefault();
        const value = e.target.value;

        if (value === '') {
            const updatedErrors = [...dropdownLinkError];
            updatedErrors[dropdownIndex] = updatedErrors[dropdownIndex] || {};
            updatedErrors[dropdownIndex][field] = false;
            setDropdownLinkError(updatedErrors);
        } else {
            const isValid = field === 'link_url' ? urlRegex.test(value) : titleRegex.test(value);
            const updatedErrors = [...dropdownLinkError];
            updatedErrors[dropdownIndex] = updatedErrors[dropdownIndex] || {};
            updatedErrors[dropdownIndex][field] = !isValid;
            setDropdownLinkError(updatedErrors);
        }
        setNewMenuLink((prevState) => {
            const updatedDropdown = [...prevState.dropdown];
            updatedDropdown[dropdownIndex][field] = (field === 'link_url'? value.trim(): value);
            return {
                ...prevState,
                dropdown: updatedDropdown,
            };
        });
    };

    // Function to display the value with preserved spaces
    const displayValue = (value) => {
        return value.replace(/ /g, '\u00A0');
    };

    return (
        menuModal && (
            <div className="relative z-[101]">
                <div className="fixed inset-0 bg-black bg-opacity-25 z-[10]"> 
                    <div className="absolute left-0 top-1/2 -translate-y-1/2 z-[1055] h-full w-full  overflow-y-auto overflow-x-hidden outline-none" >
                        <div className='flex min-h-full items-center justify-center p-4 text-center md:w-auto w-full md:relative absolute top-1/2 translate-y-[-50%]'>  
                            <div id='add-menu-link-modal' className="pointer-events-auto relative flex w-[650px] flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none"  ref={outsideClickRef}>
                                <div id='menu-link'
                                    type="button"
                                    className="flex flex-shrink-0 border-b border-[#A1A1A9] items-center justify-between rounded-t-md p-4">
                                    <p className="text-[22px] text-[#343434] leading-normal font-medium pl-3">Add menu link</p>
                                    {/* Close button */}
                                    <button
                                        onClick={() => {setMenuModal(false);document.body.classList.remove('modal-open');isMobileModal(false);setMenuLinkError({
                                            title: false,
                                            url: false,
                                        });setNewMenuLink({
                                            title: '',
                                            link_url: '',
                                            dropdown: [{ title: '', link_url: ''}]
                                        })}}
                                        type="button"
                                        className="box-content rounded-none border-none"
                                        aria-label="Close">
                                        <img src="/images/CloseButton-modalcontent.svg" alt="img" loading="lazy" />
                                    </button>
                                </div>
                                {/* Modal body */}
                                <div id="menu-link-modal" className="relative p-4 sm:mx-16 mt-6 mx-10 text-left">
                                    <div>
                                        <label
                                            className="font-medium text-[#343434] text-[12px] leading-normal "
                                            htmlFor="menulink_title">
                                            Link title
                                        </label>
                                        <input
                                            type="text"
                                            id="menulink_title"
                                            maxLength={28}
                                            className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] leading-normal font-medium text-[#343434] placeholder:text-[#343434]"
                                            placeholder=""
                                            value={displayValue(newMenuLink.title)}
                                            onChange={(e) => {
                                                handleMenuLinkChange(e, 'title');
                                            }}
                                        />
                                        {menuLinkError.title && (
                                            <p className="text-[10px] pt-1.5 font-medium leading-normal error-msg2 text-[#984848]">
                                                Title can only contain letters and spaces
                                            </p>
                                        )}
                                    </div>
                                    {!isAddingDropdown && <div className="pt-4">
                                        <label
                                            className="font-medium text-[#343434] text-[12px] leading-normal"
                                            htmlFor="menulink_url">
                                            Link URL
                                        </label>
                                        <input
                                            type="text"
                                            id="menulink_url"
                                            value={newMenuLink.link_url}
                                            onChange={(e) => {
                                                handleMenuLinkChange(e, 'link_url');
                                            }}
                                            className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] leading-normal font-medium text-[#343434] placeholder:text-[#343434]"
                                            placeholder=""
                                        />
                                        {menuLinkError.link_url && (
                                            <p className="text-[10px] pt-1.5 font-medium leading-normal error-msg2 text-[#984848]">
                                                Enter a valid URL
                                            </p>
                                        )}
                                    </div>}
                                    <div className="relative checkbox mt-4 checkbox-terms flex items-center min-h-[1.5rem]">
                                        <input
                                            type="checkbox"
                                            value=""
                                            id="exampleCheck2"
                                            checked={isAddingDropdown}
                                            onChange={() => {setIsAddingDropdown(!isAddingDropdown);setNewMenuLink((prevState) => ({
                                                ...prevState,
                                                dropdown: [
                                                    {
                                                        title: '',
                                                        link_url: '',
                                                    },
                                                ],
                                            }));}}
                                        />
                                        <label
                                            className="inline-block text-[12px] font-medium leading-normal pl-[10px] hover:cursor-pointer"
                                            htmlFor="exampleCheck2">
                                            Add dropdown
                                        </label>
                                    </div>
                                    {isAddingDropdown && (
                                        <>
                                            <div className='overflow-auto max-h-[215px] customScrollbar pr-3'>
                                                {newMenuLink.dropdown.map((dropdownLink, dropdownIndex) => (
                                                    <div className="flex items-baseline justify-between relative" key={dropdownIndex}>
                                                        <div>
                                                            <label
                                                                id = "drop-link"
                                                                className="font-medium text-[#343434] text-[12px] leading-normal"
                                                                htmlFor={dropdownIndex}>
                                                                Link title
                                                            </label>
                                                            <input
                                                                type="text" test-id='dropdown-link-title'
                                                                maxLength={28}
                                                                id={dropdownIndex}
                                                                className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] leading-normal font-medium text-[#343434] placeholder:text-[#343434]"
                                                                placeholder=""
                                                                value={dropdownLink.title}
                                                                onChange={(e) => handleDropdownChange(dropdownIndex, 'title', e)}
                                                            />
                                                            {dropdownLinkError[dropdownIndex] !== undefined && dropdownLinkError[dropdownIndex].title && (
                                                                <p className="text-[10px] pt-1.5 font-medium leading-normal error-msg2 text-[#984848]">
                                                                    Title can only contain letters and spaces
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="ml-4">
                                                            <label
                                                                id = "drop-url"
                                                                className="font-medium text-[#343434] text-[12px] leading-normal"
                                                                htmlFor={dropdownIndex + 1}>
                                                                Link URL
                                                            </label>
                                                            <input test-id='dropdown-link-url'
                                                                type="text"
                                                                id={dropdownIndex + 1}
                                                                className="border border-[#A1A1A9] w-full px-3 py-3 outline-none text-[14px] leading-normal font-medium text-[#343434] placeholder:text-[#343434]"
                                                                placeholder=""
                                                                value={dropdownLink.link_url}
                                                                onChange={(e) => handleDropdownChange(dropdownIndex, 'link_url', e)}
                                                            />
                                                            {dropdownLinkError[dropdownIndex] !== undefined &&
                                                    dropdownLinkError[dropdownIndex].link_url && 
                                                    (
                                                        <p className="text-[10px] pt-1.5 font-medium leading-normal error-msg4 text-[#984848]">
                                                            Enter a valid URL
                                                        </p>
                                                    )}
                                                        </div>
                                                        <div className='w-10 relative'>
                                                            <div className="absolute top-0 right-0 flex items-center" onClick={() => deleteDropdownLink(dropdownIndex)}>
                                                                <img src="/images/delete-icon.svg" className="ml-3 pt-5 cursor-pointer" alt="delete icon" loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <button type="button" disabled={newMenuLink.dropdown.length===5} onClick={() => addDropdownLink()} className={`w-fit flex items-center mt-3 rounded-md ${newMenuLink.dropdown.length===5?'opacity-50 cursor-auto':'cursor-point'}`}>
                                                <img src="/images/addlinks-icon.svg" id="dropdown-menu" alt="add-links-icon" className={`${newMenuLink.dropdown.length===5?'cursor-auto':'cursor-pointer'}`} loading="lazy" />
                                                <span className="px-1 py-3 text-[12px] leading-normal font-medium text-[#343434] text-left w-fit">
                                                    <span className={`${newMenuLink.dropdown.length===5?'cursor-auto':'cursor-pointer'}`}> Add another dropdown link </span>
                                                </span>
                                            </button>
                                        </>
                                    )}
                                    {/* Button content */}
                                    <div className="flex justify-center drop-shadow-s3 sm:mt-7 my-3 sm:mb-6" data-te-modal-dismiss aria-label="Close">
                                        <button id='create-btn'
                                            type="button"
                                            disabled={disableCreateButton}
                                            onClick={() => addMenuLink()}
                                            className="bg-[#282828] rounded-md text-[14px] text-[#fff] leading-normal mt-2.5 font-medium px-[20px] py-[12px] w-[135px]
                                    disabled:bg-[#A1A1A9] disabled:cursor-auto">
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}
