/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, {useState, useEffect} from 'react'
import { motion } from 'framer-motion'
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../../config';
import Button from '../../../CommonComponent/button';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { passwordFormate, clearRegistrationData, verifyOtp } from '../registrationSlice';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Amplify, Auth, Hub } from 'aws-amplify';
// import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';


export default function RegistrationForm(props) {
    const {registration, showPassword, showConfirmPassword, handleInputChange, passwordValid, setShowPassword, isValidEmail, isMatch,
        setShowConfirmPassword, handleSubmit, handleCaptcha, recaptcha, loader, setRecaptcha, handleBlur, handlePasswordBlur, termsAcceptError, setTermsAcceptError} = props;
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [customState, setCustomState] = useState(null);
    const Navigate = useNavigate();

    /* The above code is a React useEffect hook that is used to listen for authentication events using
    the AWS Amplify Hub. */
    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
            case "signIn":
                localStorage.setItem('user',data.username);
                setUser(data);
                dispatch(verifyOtp(data.username));
                Navigate('/')
                break;
            case "signOut":
                setUser(null);
                break;
            case "customOAuthState":
                setCustomState(data);
            }
        });
        return unsubscribe;
    }, []);

    useEffect(()=>{
        if(registration.password.length===0){
            dispatch(passwordFormate(false));
        }
        else if(passwordValid.length===false || passwordValid.uppercase===false || passwordValid.lowercase===false || passwordValid.number===false || passwordValid.symbol===false){
            dispatch(passwordFormate(true))
        }
        if(passwordValid.length===true && passwordValid.lowercase===true && passwordValid.number===true && passwordValid.symbol===true && passwordValid.uppercase===true){
            dispatch(passwordFormate(false));
        }

    },[registration.password])
    
    return (

        <motion.div initial={{ opacity: 0, scaleX: '0' }}
            animate={{ opacity: 1, scaleX: '1' }}
            transition={{ duration: 1 }} 
            className="xl:w-[30%] lg:w-[35%] md:w-[45%] sm:w-[60%] w-full  bg-white mt-12 md:float-right lg:mr-20 
                md:mr-5 mx-auto rounded-[10px] h-fit">
            <div className="pl-8 pr-4 pt-[26px] pb-7">
                <p className="text-[22px] font-medium text-center leading-normal">Get Started</p>
                <p className="text-lg font-medium text-[#A1A1A9] text-center leading-normal">Enter your details to register</p>
                <div className="sm:h-customh1 overflow-y-auto overflow-x-hidden customScrollbar pr-4">
                    <form id="form-register" autoComplete="off" className="mt-2">
                        <div className="flex flex-col">
                            <label htmlFor="email" className={`font-medium text-[14px] leading-normal email-label flex 
                            ${registration.emailError ? 'text-[#984848]':'text-[#343434]' }`}>Email<p className='w-5 h-5' id="bypass" onClick={()=>setRecaptcha(true)}></p></label>
                            <input type="email" id="email" value={registration.email} onChange={(e)=>handleInputChange(e)}
                                onBlur={(e)=>handleBlur(e)}
                                className={`border w-full h-10 outline-none 
                            focus:border-[#343434] px-2 text-[14px] text-[#343434] font-medium leading-normal email-border 
                            ${registration.emailError?'border-[#984848]': 'border-[#A1A1A9]'}`} />
                            {registration.emailError && 
                            <motion.p 
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className="font-medium pt-1 email-err-msg text-[#984848] text-[10px] 
                            leading-normal">
                                {registration.emailError.includes('already') ? 
                                    <motion.p 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                        className="font-medium pt-1 email-err-msg text-[#984848] text-[10px] 
                                        leading-normal">
                                        {registration.emailError}{'.'}{' '}Please {' '}
                                        <Link to="/login" onClick={()=>dispatch(clearRegistrationData())} className='underline'>sign in</Link>
                                    </motion.p>
                                    : 
                                    <motion.p 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                        className="font-medium pt-1 email-err-msg text-[#984848] text-[10px] 
                                        leading-normal">{registration.emailError}</motion.p>
                                }

                            </motion.p>}
                        </div>
                        <div className="mt-2 relative flex flex-col">
                            <label htmlFor="password" className={`font-medium text-[14px] leading-normal email-label 
                            ${registration.passwordError ? 'text-[#984848]':'text-[#343434]' }`}>Password</label>
                            <input type={showPassword?"text":"password"} id="password" value={registration.password} 
                                onChange={(e)=>handleInputChange(e)}
                                onBlur={(e)=>handlePasswordBlur(e)}
                                className={`border w-full h-10 pswd-border2 
                                    outline-none focus:border-[#343434] pl-2 pr-7 text-[14px] text-[#343434] 
                                    font-medium leading-normal ${registration.passwordError?'border-[#984848]': 'border-[#A1A1A9]'}`} />
                            <img src={showPassword?"images/eye-icon-open.svg":"images/eye-icon-close.svg"} 
                                className="absolute right-[10px] eye-close top-[36px] cursor-pointer" alt="eye-icon" 
                                loading="lazy" 
                                onClick={()=>setShowPassword(!showPassword)} />
                        </div>
                        {registration.showFormate &&
                        <motion.div initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }} 
                            transition={{
                                ease: "linear",
                                duration: 0.5,
                                x: { duration: 1 },
                                delay: 0.1
                            }}
                            className="error-condition">
                            <div className="flex mt-2">
                                <img src={passwordValid.length ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p className={`text-[10px] font-medium pl-1.5 
                                ${passwordValid.length ? 'text-[#489892]' : 'text-[#717179]'}`}>Between 8 and 16 characters</p>
                            </div>
                            <div className="flex mt-1.5">
                                <img src={passwordValid.uppercase ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p className={`text-[10px] font-medium pl-1.5 
                                ${passwordValid.uppercase ? 'text-[#489892]' : 'text-[#717179]'}`}>Uppercase letter</p>
                            </div>
                            <div className="flex mt-1.5">
                                <img src={passwordValid.lowercase ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p className={`text-[10px] font-medium pl-1.5 
                                ${passwordValid.lowercase ? 'text-[#489892]' : 'text-[#717179]'}`}>Lowercase letter</p>
                            </div>
                            <div className="flex mt-1.5">
                                <img src={passwordValid.number ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p className={`text-[10px] font-medium pl-1.5 
                                    ${passwordValid.number ? 'text-[#489892]' : 'text-[#717179]'}`}>Number</p>
                            </div>
                            <div className="flex mt-1.5">
                                <img src={passwordValid.symbol ?"images/greentick-small.svg":"images/cross-icon-small.svg"} 
                                    alt="img" loading="lazy" /> 
                                <p className={`text-[10px] font-medium pl-1.5 
                                ${passwordValid.symbol ? 'text-[#489892]' : 'text-[#717179]'}`}>Symbol</p>
                            </div>
                        </motion.div>}
                        <div className="mt-2 relative flex flex-col">
                            <label htmlFor="confirmPassword" 
                                className={`font-medium text-[14px] leading-normal email-label 
                                ${registration.passwordError ? 'text-[#984848]':'text-[#343434]' }`}>Confirm Password</label>
                            <input type={showConfirmPassword?"text":"password"} id="confirmPassword" 
                                onChange={(e)=>handleInputChange(e)}
                                value={registration.confirmPassword}
                                onBlur={(e)=>handlePasswordBlur(e)}
                                className={`border w-full h-10 outline-none pswd-border focus:border-[#343434] 
                                pl-2 pr-7 text-[14px] text-[#343434] font-medium leading-normal 
                                ${registration.passwordError?'border-[#984848]': 'border-[#A1A1A9]'}`} />
                            <img src={showConfirmPassword?"images/eye-icon-open.svg":"images/eye-icon-close.svg"} 
                                className="absolute eye-close1 right-[10px] top-[36px] cursor-pointer" 
                                alt="eye-icon" onClick={()=>setShowConfirmPassword(!showConfirmPassword)} loading="lazy" />
                            {registration.passwordError&&<motion.p 
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className="font-medium pt-1 pswd-err-msg text-[#984848] text-[10px] 
                                leading-normal">{registration.passwordError}</motion.p>}
                        </div>
                        {termsAcceptError!==''&&<p id="error-message" className='text-[#984848] text-[10px] leading-normal pt-2.5'>{termsAcceptError}</p>}
                        <div className="relative checkbox checkbox-terms flex items-center mt-3 min-h-[1.5rem]">
                            <input
                                type="checkbox"
                                id="termsAccepted"
                                checked={registration.termsAccepted}
                                onChange={(e)=>handleInputChange(e)}
                            />
                            <label
                                className="inline-block text-[8px] sm:text-[10px] xxs:text-[9px] font-medium leading-normal 
                                pl-[10px] hover:cursor-pointer"
                                htmlFor="termsAccepted">
                                I agree to the <a href="https://www.indy.auction/indy.auction-purchasing-the-right-to-use-and-sell-on-indy.auction" 
                                    className="underline" target="_blank" rel="noreferrer">
                                    Terms of Use & Terms and Conditions.*</a>
                            </label>
                        </div>
                        <div className="relative flex items-center mt-3 min-h-[1.5rem]">
                            <label
                                className="inline-block text-[8px] sm:text-[10px] xxs:text-[9px] font-medium leading-normal 
                                pl-[10px] hover:cursor-pointer"
                                htmlFor="termsAccepted">
                                To find out how we use your data, please read our <a href="https://www.indy.auction/indy.auction-global-privacy-policy" 
                                    className="underline" target="_blank" rel="noreferrer">
                                    Privacy Policy.*</a>
                            </label>
                        </div>
                        <div className="relative checkbox checkbox-terms flex items-center mt-3 min-h-[1.5rem]">
                            <input
                                type="checkbox"
                                id="newsletterSubscribed"
                                checked={registration.newsletterSubscribed}
                                onChange={(e)=>handleInputChange(e)} 
                            />
                            <label
                                className="inline-block pt-1 text-[8px] sm:text-[10px] xxs:text-[9px] font-medium leading-normal 
                                pl-[10px] hover:cursor-pointer"
                                htmlFor="newsletterSubscribed">
                                Please subscribe me to the 
                                <a href="https://theauctioncollective.us16.list-manage.com/subscribe?u=bbf7a763db594a9617be3e5d5&id=fda97fec71" 
                                    className="underline" target="_blank" rel="noreferrer"> Auction Insight's </a> newsletter
                            </label>
                        </div>
                        {registration.email!=='' && registration.password!=='' && registration.termsAccepted && 
                        <div className='pt-3.5'>
                            <ReCAPTCHA
                                sitekey= {config.SITEKEY}
                                onChange={(token)=>handleCaptcha(token)}
                            />
                        </div>
                        }
                        
                        <Button onclick={(e)=>handleSubmit(e)} loader={loader} type="button"
                            disabled={registration.email===''|| registration.password==="" || registration.confirmPassword==='' || loader
                                || registration.termsAccepted===false || recaptcha===false || (registration.passwordError!==null || !isMatch ) || (registration.emailError!==null || !isValidEmail ) ||
                                (!passwordValid.length || !passwordValid.lowercase || !passwordValid.uppercase || !passwordValid.number || !passwordValid.symbol)} text="Register" />
                        <a id="facebook-btn"
                            className="my-3 register-btn2 flex w-full text-[#52525A] items-center justify-center 
                            rounded bg-transparent px-7 py-3 text-center text-[14px] font-medium leading-normal  
                            transition duration-150 ease-in-out border border-[#A1A1A9]  focus:outline-none"
                            // href="#!"
                            role="button"
                            data-te-ripple-init
                            onClick={() => {registration.termsAccepted==false?setTermsAcceptError('Please accept the Terms & Conditions before proceeding.'):
                                (setTermsAcceptError(''),Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook}))}}
                            data-te-ripple-color="light">
                            <img src="images/facebook-blue-icon.svg" className="mr-1.5" alt="img" loading="lazy" />
                            Register with Facebook
                        </a>

                        <a id="google-btn"
                            className="my-3 flex w-full items-center justify-center rounded bg-transparent px-7 py-3 
                            text-center text-[14px] font-medium leading-normal text-[#52525A] border border-[#A1A1A9] 
                            transition duration-150 ease-in-out  focus:outline-none"
                            // href="#!"
                            role="button"
                            data-te-ripple-init
                            onClick={() => {registration.termsAccepted==false?setTermsAcceptError('Please accept the Terms & Conditions before proceeding.'):
                                (setTermsAcceptError(''),Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google}))}}
                            data-te-ripple-color="light">
                            <img src="images/google-icon.svg" className="mr-1.5" alt="img" loading="lazy" />
                            Register with Google
                        </a>
                    </form>
                </div>
            </div>    
        </motion.div>  
    )
}
