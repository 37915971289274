/* eslint-disable max-len */
import React, { useEffect } from 'react'
import LayoutNavbar from '../commonLayout/layoutNavbar'
import AuctionDetails from '../commonLayout/auctionDetails'
import LotListingHeader from '../commonLayout/lotListingHeader'
import LotSection from '../commonLayout/lotSection'
import LayoutFooter from '../commonLayout/layoutFooter'
import { useLocation } from 'react-router-dom'
import config from '../../../../config'
import { selectFontStyle } from '../../../../CommonComponent/SelectFontStyle'

export default function ClassicPreview({auctionData,startHour,startMin,endHour,endMin,endDate,startDate}) {
    const path=useLocation();
    return (
        <div 
            className="md:mt-9 mt-5 md:mx-10 mx-5 w-full overflow-x-hidden bg-[#FFF] 
        rounded-t-lg h-fit xl:w-[74%] lg:w-[70%] md:w-[60%]"
            style={{backgroundColor: auctionData.content_area['background_color'],color: auctionData.content_area['text_color']}}>
            {/* Main navigation container */}
            <LayoutNavbar auctionData={auctionData}/>
            <div className="border-b py-[13px] pl-[23px] border-[#D4D4D8]">
                <p className="text-[11px] font-medium leading-normal" style={{fontFamily: selectFontStyle(auctionData.font['hearder_font'])}}>{auctionData.title===''?'Auction title here':auctionData.title}</p>
            </div>
            <div className="xl:px-[90px] lg:px-[60px] sm:px-[30px] px-[15px]">
                {/* <!-- auction contents here --> */}
                <div className="w-full llg:flex pt-10 llg:justify-between">
                    <AuctionDetails auctionData={auctionData} startDate={startDate} 
                        endDate={endDate} 
                        startHour={startHour}
                        startMin={startMin}
                        endHour={endHour}
                        endMin={endMin}
                    />
                    <div className="llg:w-[50%]  flex h-[350px]  llg:h-auto justify-center items-center bg-[#E6E6E6]">
                        <div className="flex justify-center items-center flex-col h-[350px] w-full">
                            <img id='auction-img' src={auctionData.auction_image!==''?`${config.Storage.CDN}${auctionData.auction_image}`:"/images/lead-auction-img.png"} alt="lead-auction-img" className={auctionData.auction_image!==''?'w-full h-full':'w-auto h-auto'} loading="lazy" />
                            {auctionData.auction_image===''&&<p className="text-[8px] text-[#52525A] pt-2 text-center font-semibold 
                            leading-normal">Lead auction image</p>}
                        </div>
                    </div> 
                    {/* <!-- after adding preview image  --> */}
                    <div className="llg:w-[50%] hidden w-full h-auto">
                        <img src="/images/preview-image.png" alt="img" loading="lazy" />
                    </div>
                </div>
                {/* <!-- terms condition & social media links contents --> */}
                <div className="w-full m-0 flex"  style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                    <div className="flex justify-center w-[50%]  items-center">
                        <p className="text-[8px] text-center leading-normal">FAQs +</p>
                        <p className="text-[8px] pl-2 text-center 
                        leading-normal">Terms and Conditions +</p>
                    </div>
                    {/* <!-- social media link --> */}
                    <div className="flex w-[50%] justify-end items-center">
                        <img src="/images/add-calendar-icon.svg" alt="add-calender-icon" className="cursor-default" loading="lazy" />
                        <img src="/images/share-icon.svg" alt="share-icon" className="cursor-default" loading="lazy" />
                    </div>
                </div>
                {/* <!-- listing content --> */}
                <LotListingHeader auctionData={auctionData}/>
            </div>
            {/* <!-- listing-page --> */}
            <div className="border-t mt-3 sm:mb-20 mb-5 xl:px-[70px] lg:px-[50px] px-[20px] w-full border-[#E6E6E6]">
                <div className="flex flex-wrap -mx-5">
                    {[...Array(8)].map((item, index)=>(
                        <LotSection key={index} index={index} auctionData={auctionData}/>
                    ))}
                </div>
            </div>
            {/* <!-- footer content --> */}
            <LayoutFooter auctionData={auctionData}/>
        </div>
    )
}
