/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import { Menu } from '@headlessui/react'
import { useState } from 'react';
import NoteModal from './noteModal';
import config from '../../../config';
import {Link, useNavigate, useParams} from 'react-router-dom'
import DeleteModal from './DeleteModal';
import PublishAuctionModal from '../auctionView/publishAuctionModal';
import { dataService } from '../../../services/dataService';
import { useDispatch } from 'react-redux';
import { timeZoneMap } from '../../../CommonComponent/staticValues';
import UnPublishAuctionModal from '../auctionView/unpublishAuctionModal';
import CancelModal from './DeactivateModal';
import moment from 'moment/moment';
import { Auth } from 'aws-amplify';
import { logout } from '../../auth/registrationSlice';

export default function TableRow(props){

    const { auctionData, addNoteHandler,cloneHandler,isDeleteModalOpen,loader,setLoader,
        setIsDeleteModalOpen,handleDelete, isCancelModalOpen,setMessage,
        setIsCancelModalOpen, handleFetch, domain, fetchAuctions,setErrorMessage}= props;

    const [open,setOpen]=useState(true);
    const [isOpenModal,setIsOpenModal]=useState(false);
    const [note, setNote]=useState('');
    const [fetchNote, setFetchNote]=useState('')
    const Navigate=useNavigate();
    const [auctionId, setAuctionId]=useState('');
    const plan_type=localStorage.getItem('plan_type');
    const [selectedRow, setSelectedRow]=useState(null);
    const noteRegex = /^(?!\s).*$/;
    const navigate = useNavigate()
    const [selectedId,setSelectedId] = useState('')
    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const [unpublishModalOpen,setUnpublishModalOpen]= useState(false); 
    const [publishLoader,setPublishLoader] = useState(false)
    const [isRequiredFieldEmpty, setIsRequiredFieldEmpty] = useState(false)
    const [isPublished, setIsPublished] = useState(false)
    const [isUnPublished, setIsUnPublished] = useState(false)
    const [lotInfo, setLotInfo] = useState([])
    const [auctionInfo, setAuctionInfo] = useState({})
    const [noLot,setNoLot]=useState(false);
    const [notConnect, setNotConnect]=useState(null)
    const [lots,setLots]=useState([])
    const user = (localStorage.getItem('user'));
    const {id} = useParams();
    const [loading,setLoading]=useState(true);
   


    const convertTimestampToDayMonthYear = (timestamp, time_zone) => {
        
        if(timestamp==0){
            return '-';
        }
        // const time =moment.tz(timestamp, timeZoneMap[time_zone ? time_zone : 'Etc/GMT']).format("MMM DD YYYY")

        const options = {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            timeZone: timeZoneMap[time_zone ? time_zone : 'Etc/GMT']
        };
        const formatter = new Intl.DateTimeFormat("en-GB", options);

        const start_date = new Date(timestamp);
        const formattedDate = formatter.format(start_date);
        const [date1, time] = formattedDate.includes("at") ? formattedDate.split("at ") : formattedDate.split(", ");
        
        return date1.includes('1970') ? '-' : date1;
    };

    /**
     * The saveHandler function is used to handle saving or deleting a note in a React component.
     */
    const saveHandler=(type)=>{
        if(type==='delete'){
            addNoteHandler(auctionId,'');
        }else{
            addNoteHandler(auctionId,note);
        }
        setIsOpenModal(false);
    }

    /**
     * The function `noteHandler` checks if a given note matches a regular expression and sets the note
     * if it does.
     * @returns If the note passes the test of the noteRegex, then the setNote function is called with
     * the note as an argument. If the note does not pass the test, nothing is returned.
     */
    const noteHandler=(note)=>{
        if(noteRegex.test(note)){
            setNote(note)
        }else{
            return;
        }
    }


    /**
     * The function `handlePublishAuction` is an asynchronous function that publishes an auction and
     * updates the state accordingly.
     */
    const handlePublishAuction = async(auctionId)=>{
        setPublishLoader(true)

        const body = {
            "email": user
        }
        if(!((auctionInfo.auction_image =="" || auctionInfo.title=="" || auctionInfo.description=="" || auctionInfo.currency=="" ) || 
            (auctionInfo.start_date =="" || auctionInfo.end_date=="" || auctionInfo.time_zone=="" || auctionInfo.start_date ==null || auctionInfo.end_date==null ) || 
            (((auctionInfo.extension_type =="Cascade" || auctionInfo.extension_type =="Individual lots") 
            && auctionInfo.extension_time_between_lots=="") || auctionInfo.extension_time=="" || auctionInfo.registration_type=="" 
            || ((auctionInfo.add_buyer_fees =="Add percentage"  && auctionInfo.percentage=="") || ( auctionInfo.add_buyer_fees=="Add fixed fee" 
            && auctionInfo.fees=="" ) ))) ){

            if(lotInfo.total_lots>0){
                const data = await dataService.publishAuction(auctionId)
                if(data.error==true){
                    if(data.message.response.data.message==="Auction is already published or is Accepting bids"){
                        setErrorMessage('Auction is already in Published State')
                        setPublishModalOpen(false)
                        fetchAuctions()
                        setTimeout(() => {
                            setErrorMessage('')
                        }, 3000);
                    }else if(data.message.response.data.message==="Some lots are missing lot images"){
                        setPublishModalOpen(false)
                        setIsRequiredFieldEmpty(true)
                        setNoLot(true);
                    }else if(data.message.response.data.message==="Stripe account not linked."){
                        setNotConnect("stripe")
                        setPublishModalOpen(false)
                        setIsRequiredFieldEmpty(true)
                    }else if(data.message.response.data.message.includes("verification")){
                        setNotConnect("verification")
                        setPublishModalOpen(false)
                        setIsRequiredFieldEmpty(true)
                    }
                    else if(data.message.response.data.message==="Unauthorised to perform this action."){
                        Auth.signOut();
                        Navigate('/login')
                        dispatch(logout())
                    }
                }else{
                    setIsPublished(true)
                    setPublishModalOpen(false)
                    setPublishLoader(false)
                    fetchAuctions()
                }
            }else{
                setPublishModalOpen(false)
                setIsRequiredFieldEmpty(true)
            }
        }else{
            setPublishModalOpen(false)
            setIsRequiredFieldEmpty(true)
        }
            

        setPublishLoader(false)
        // setIsPublished(false)
    }
    

    const handleLotList = async(auctionId)=>{
        setLoading(true);
        const response = await dataService.LotList(`?auction_id=${auctionId}`);
        setLotInfo(response.data)
        setLoading(false)
    }

    const handleTemplate=(template)=>{
        if(template==="Classic"){
            localStorage.setItem('selectedTemplateId','1')
            return "classic";
        }else if(template==="" || template==="Single lot" || template==="Single Lot"){
            localStorage.setItem('selectedTemplateId','2')
            return "single-lot";
        }else if(template==="Standalone"){
            localStorage.setItem('selectedTemplateId','3')
            return "standalone";
        }
    }

    const dispatch=useDispatch();

    const handleUnpublishAuction = async(auctionId)=> {
        setPublishLoader(true)
        const body={
            "type": "UNPUBLISH"
        }
        const data = await dataService.UnpublishAuction(auctionId, body)
        if(data.error==true){
            setUnpublishModalOpen(false)
            setPublishLoader(false)
            setErrorMessage('Auction not in published state')
            handleFetch()
            window.scrollTo(0, 0);
            setTimeout(()=>{
                setErrorMessage("")
            },3000)
        }else{
            setIsUnPublished(true)
            setUnpublishModalOpen(false)
            setMessage('Auction unpublished successfully')
            handleFetch();
            setPublishLoader(false);
            window.scrollTo(0, 0);
            setTimeout(()=>{
                setMessage("")
            },3000)
        }
    }
    const handleCancel = async(auctionId)=>{
        setLoader(true)
        const body={
            "type": "CANCEL"
        }
        const response = await dataService.UnpublishAuction(auctionId,body);
        if(response.error===false){
            setLoader(false)
            setIsCancelModalOpen(false)
            setMessage('Auction cancelled successfully');
            setTimeout(() => {
                setMessage('')
            }, 4000);
            handleFetch() 
            // Navigate('/auctions')
            // localStorage.setItem('AuctionDeactivate', 'success');
        }
    }
    return(
        <>
            {auctionData.map((auction,index)=>
                <tr key={index} 
                    className={`border border-[#DEDEDE] table-row  h-14
                    hover:bg-[#F4F4F5] ${selectedRow===index ? 'bg-[#F4F4F5]' : ''} `}>
                    <td className=" text-[#343434]  px-4 py-3 text-[16px] leading-normal font-medium">{auction.auction_id}</td>
                    <td><img 
                        src={` ${(auction.auction_image===undefined || auction.auction_image==='') 
                            ? 'images/auction-img1.png' : `${config.Storage.CDN}${auction.auction_image}`} `}  
                        className=" px-4 py-3 w-[95px] h-[77px]" alt="img" loading='lazy' /></td>
                    <td className=" text-[#343434] px-4 py-3 text-[16px] text-center leading-normal font-medium cursor-pointer hover:underline"
                        onClick={() => navigate(`/auctions/${auction._id}/${auction.auction_id}/overview`, {
                            state: auction.auction_id
                        })} >
                        {auction.title==='' ? '-' : (auction.title.length > 40 ? auction.title.substring(0, 40) + '...' : auction.title)}</td>
                    <td className=" text-[#343434] px-4 py-3 text-[16px] text-center leading-normal font-medium">
                        {(auction.start_date===null || auction.start_date==='')  ? '-' : convertTimestampToDayMonthYear(auction.start_date, auction.time_zone)}</td>
                    <td className=" text-[#343434] px-4 py-3 text-[16px] text-center leading-normal font-medium">
                        {(auction.end_date===null || auction.end_date==='') ? '-' : convertTimestampToDayMonthYear(auction.end_date, auction.time_zone)}</td>
                    <td className="px-4 py-3">
                        <div className="flex justify-center">
                            <span className={` ${auction.status==='Published' ? 'text-[#B98B47] bg-[#E3C79D]':
                                (auction.status==='Draft' ? 'text-[#343434] bg-[#B8D5DB]':
                                    (auction.status==='Completed' ?'text-[#343434] bg-[#DDD1D5]':
                                        (auction.status==='Archived' ? 'text-[#343434] bg-[#D4D4D8]':
                                            (auction.status==='Cancelled' ?'text-[#984848] bg-[#CA8787]':
                                                'text-[#489892] bg-[#A8D9C8]'))))}
                            py-[8px] px-[16px] text-[12px]  
                            rounded-[42px] text-end leading-normal font-semibold`}>{auction.status}</span>
                        </div>
                    </td>
                    
                    {(auction.note!==undefined && auction.note!=='') ?
                        <td className=" text-[#343434] py-3 text-[16px] text-center leading-normal font-medium mx-auto"
                            onClick={()=>{setIsOpenModal(true); setAuctionId(auction.auction_id); 
                                setFetchNote(auction.note)}}>
                            <img id='view-note' src="images/draft-note.svg" className=" cursor-pointer" alt="img"
                                data-te-toggle="modal"
                                data-te-target="#exampleModal2"
                                            
                                data-te-dropdown-item-ref />
                        </td>:
                        <td className=" text-[#343434] px-4 py-3 text-[16px] text-center leading-normal font-medium">
                        </td>
                    }
                    <td className=" sm:relative  sm:bg-transparent bg-[#fff] sticky right-0">
                        <div className="h-fit py-[43px] sm:shadow-none shadow-bs7 sm:px-4 px-2">
                            <span className="flex justify-end">
                                <Menu as="div" className="relative" >
                                    <Menu.Button onClick={()=>{setSelectedRow(index),handleLotList(auction.auction_id),setAuctionInfo(auction), setNoLot(false),setNotConnect(null)}} >
                                        <img src="/images/three-dots-icon-black.svg" alt="img" 
                                            className="cursor-pointer three-dots-icon action-bar" type="button"
                                            id="dropdownMenuButton1" data-testid="action-bar" />
                                    </Menu.Button>
                                    <Menu.Items>
                                        <div className="dropdown-cont absolute overflow-hidden rounded-[10px] float-left 
                                                    m-0 w-[150px] py-2 min-w-max right-0 z-[50] 
                                                    list-none  border-none bg-white bg-clip-padding 
                                                    text-left text-base shadow-bs3 px-2">
                                            <Menu.Item data-testid='view-auction' className="px-2" >
                                                <button type='button'
                                                    onClick={() => {navigate(`/auctions/${auction._id}/${auction.auction_id}/overview`, {
                                                        state: auction.auction_id
                                                    })}}
                                                    className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2
                                                            text-[12px] font-medium text-[#343434] text-left hover:bg-[#E6E6E6] hover:rounded-[11px]">
                                                    View Details</button>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <button type='button' data-testid="publish-auction"
                                                    
                                                    disabled={auction.status=='Cancelled' || auction.status=="Accepting bids" || auction.status=="Completed"}
                                                    onClick={() => {
                                                        auction.status==='Published' ? setUnpublishModalOpen(true) : setPublishModalOpen(true)
                                                    }}
                                                    className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] text-left hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                            disabled:cursor-auto  disabled:text-[hsl(240,4%,65%)]">
                                                    {auction.status==='Published'?'Unpublish':'Publish'}</button>
                                                
                                            </Menu.Item>
                                            <Menu.Item  id='edit-auction'>
                                                <button 
                                                    disabled={auction.status=='Completed' || auction.status =='Cancelled'}
                                                    onClick={(e)=>{
                                                        e.preventDefault();
                                                        const path = `/auctions/edit/${auction._id}/${auction.auction_id}/${handleTemplate(auction.template_name)}`;
                                                        Navigate(path, {state: auction===undefined?'':auction});
                                                    }}
                                                    className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                            text-[12px] font-medium text-[#343434] text-left disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto">Edit</button>
                                            </Menu.Item>
                                            {(auction.status!=="Accepting bids" && auction.status!=="Cancelled") && <Menu.Item>
                                                <button  disabled={plan_type.includes("Free")}  data-testid="clone-auction"
                                                    onClick={()=>cloneHandler(auction._id)}
                                                    className="disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto block 
                                                        w-full whitespace-nowrap bg-transparent pl-3 pr-5 text-left hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                            py-2 text-[12px] font-medium text-[#343434] cursor-pointer"
                                                >Clone</button>
                                            </Menu.Item>}
                                            <Menu.Item>
                                                <p
                                                    onClick={()=>{setIsOpenModal(true); setAuctionId(auction.auction_id); 
                                                        setFetchNote(auction.note)}}
                                                    className="block w-full whitespace-nowrap bg-transparent pl-3 pr-5 py-2 
                                                            text-[12px] font-medium text-[#343434] cursor-pointer hover:bg-[#E6E6E6] hover:rounded-[11px]"
                                                >{(auction.note===undefined || auction.note==='') ? 'Add Note' 
                                                        : 'Delete Note'}</p>
                                            </Menu.Item>
                                            <Menu.Item>
                                                {auction.status!=='Accepting bids'?
                                                    <button 
                                                        disabled={auction.status==="Accepting bids" || 
                                                        auction.status==='Completed'}
                                                        data-testid="delete-auction"
                                                        onClick={()=>{setIsDeleteModalOpen(true),
                                                        setSelectedId(auction.auction_id)}}
                                                        className="disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto block 
                                                        w-full whitespace-nowrap bg-transparent pl-3 pr-5 text-left hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                            py-2 text-[12px] font-medium text-[#343434] cursor-pointer 
                                                            delete-auction"
                                                    >Delete</button>
                                                    :
                                                    <button 
                                                        type='button'
                                                        data-testid="deactivate"
                                                        onClick={()=>{setIsCancelModalOpen(true),
                                                        setSelectedId(auction.auction_id)}}
                                                        className="disabled:text-[hsl(240,4%,65%)] disabled:cursor-auto block 
                                                    w-full whitespace-nowrap bg-transparent pl-3 pr-5 text-left hover:bg-[#E6E6E6] hover:rounded-[11px]
                                                        py-2 text-[12px] font-medium text-[#343434] cursor-pointer 
                                                        delete-auction"
                                                    >Cancel</button>}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Menu>
                            </span>  
                        </div> 
                    </td>
                </tr>
            )}
            
            {isOpenModal && 
            <NoteModal setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal}>
                <div className="relative sm:mx-8 mx-5 mb-5">
                    {(fetchNote==='' || fetchNote===undefined) && 
                        <p className="pb-1 mt-8 leading-normal text-[12px]  text-[#343434] 
                                         font-medium">Add notes</p>}
                    {(fetchNote==='' || fetchNote===undefined) ? 
                        <textarea id='text-area' name="text-area" onChange={(e)=>{noteHandler(e.target.value)}}
                            className="resize-none outline-none w-full h-[120px] px-2 
                                        py-2.5 overflow-y-auto overflow-x-hidden customScrollbar border border-[#A1A1A9] 
                                        placeholder:text-[14px] placeholder:text-[#A1A1A9] text-[16px] leading-normal 
                                        font-medium text-[#343434]" placeholder="Type here"  cols="10" rows="10"
                            value={note}>
                            {note}</textarea> : 
                        <p name="text-area"
                            className="resize-none outline-none mt-6 w-full px-4 break-all 
                                        py-2.5 overflow-y-auto overflow-x-hidden customScrollbar 
                                        placeholder:text-[14px] text-[16px] leading-normal 
                                        font-medium text-[#343434]">
                            {fetchNote}</p>}
                    <div className="flex justify-center mt-3">
                        <button id='save-note' data-testid='save-note' disabled={(fetchNote==='' || fetchNote===undefined) && note==""}
                            className={` ${(fetchNote==='' || fetchNote===undefined) ? 'bg-[#282828] text-[#FFFFFF]' : 
                                'bg-[#fff] border border-[#A1A1A9] text-[#52525A]'} 
                        px-[31px] py-[12px] leading-normal font-medium text-[14px] rounded-[6px] disabled:bg-[#D4D4D8] disabled:text-[hsl(240,4%,65%)] `} 
                            onClick={()=>saveHandler((fetchNote==='' || fetchNote===undefined) ? 'save' : 'delete')}>
                            {(fetchNote==='' || fetchNote===undefined) ? 'Save' : 'Delete note'}
                        </button>
                    </div>
                </div> 
            </NoteModal>}

            <DeleteModal isDeleteModalOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} 
                handleDelete={handleDelete} loader={loader} selectedId={selectedId} />
            <CancelModal isCancelModalOpen={isCancelModalOpen} setIsCancelModalOpen={setIsCancelModalOpen} 
                handleCancel={handleCancel} loader={loader} selectedId={selectedId} />
            
            {(publishModalOpen || isRequiredFieldEmpty || isPublished) && 
                <PublishAuctionModal notConnect={notConnect} publishModalOpen={publishModalOpen} setPublishModalOpen={setPublishModalOpen} 
                    handlePublishAuction={handlePublishAuction} loader={publishLoader} selectedId={auctionInfo.auction_id} handleFetch={handleFetch}
                    auctionData={auctionInfo} lotInfo={lotInfo} isRequiredFieldEmpty={isRequiredFieldEmpty} domain={domain} noLot={noLot}
                    setIsRequiredFieldEmpty={setIsRequiredFieldEmpty} isPublished={isPublished} setIsPublished={setIsPublished} loading={loading} />
            }

             
            {unpublishModalOpen && 
                <UnPublishAuctionModal notConnect={notConnect} unpublishModalOpen={unpublishModalOpen} setUnpublishModalOpen={setUnpublishModalOpen} 
                    handleUnpublishAuction={handleUnpublishAuction} loader={publishLoader} selectedId={auctionInfo.auction_id}
                    auctionData={auctionInfo} lotInfo={lotInfo} isRequiredFieldEmpty={isRequiredFieldEmpty} domain={domain} noLot={noLot}
                    setIsRequiredFieldEmpty={setIsRequiredFieldEmpty} isUnPublished={isUnPublished} setIsUnPublished={setIsUnPublished} loading={loading} />
            }

        </>
    );
}