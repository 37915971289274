/* eslint-disable security/detect-object-injection */
/* eslint-disable max-len */
import { Auth, Storage } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import uuid from 'react-uuid';
import Header from './header';
import ErrorMessage from '../../../CommonComponent/errorMessage';
import SuccessMessage from '../../../CommonComponent/successMessage';
import config from '../../../config';
import { dataService } from '../../../services/dataService';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { setBids } from '../bidsSlice';
import Custom404 from '../../../404';
import ToastMeassage from '../../../CommonComponent/toastMeasseage';

export default function AuctionView({unpublishModalOpen,setUnpublishModalOpen,isUnPublished, setIsUnPublished}) {
    const path = useLocation()
    const {id, uid} = useParams();
    const Navigate=useNavigate();
    const [successMessage,setSuccessMessage] = useState('')
    const [lots,setLots]=useState([])
    const [lotInfo,setLotInfo]=useState([])
    const [loading, setLoading] = useState(false)
    const [loadBids,setLoadBids]=useState(true);
    const [loadLots,setLoadLots]=useState(true);
    const [auctionData, setAuctionData] = useState({})
    const [searchParams, setSearchParams] = useSearchParams({ page:  1, per_page: 10 });
    const [searchParam,setSearchParam]=useSearchParams({ page:  1, per_page: 10, sort : "updated_at", order : "desc" });
    const [currentSortColumn, setCurrentSortColumn] = useState({key:null, order: 'asc'});
    const [isSearched, setIsSearched] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState('')
    const [uploadErrorMessage, setUploadErrorMessage] = useState('')
    const [showImportModal, setShowImportModal] = useState(false)
    const [isReorderLotModalOpen, setIsReorderLotModalOpen] = useState(false);
    const [importLoader, setImportLoader] = useState(false)
    const [domain,setDomain]=useState('');
    const [socketConn,setSocket]=useState(null);
    const [lotDetailsFromSocket,setLotDetailsFromSocket]=useState({});
    const [bidInformation, setBidInformation]=useState({})
    const [isSocket,setIsSocket]=useState(false);
    const [bids,setBidss]=useState([])
    const [bidsInfo,setBidsInfo]=useState({})
    const dispatch = useDispatch()
    const [isAuctionInvalid , setIsAuctionInvalid] =useState(false);
    const [message,setMessage] = useState('');
    const location= useLocation();
    const auction_iD= location.state;
    const [bidsExportCsvUrl, setBidsExportCsvUrl]=useState('')
    const [showExoprtToastMsg, setShowExportToastMsg]=useState(false)

    /**
     * The function `handleView` retrieves auction data from a data service and updates the form data
     * if the retrieval is successful.
     */
    const handleView = async () => {
        setLoading(true);
        const data = await dataService.viewAuction(`?auction_id=${id}`)
        if(data.error===false){
            setAuctionData(data.data.data)
        }else{
            setIsAuctionInvalid(true);
        }
        setLoading(false)
    }


    /**
     * The function `socketConnection` establishes a socket connection and handles various socket
     * events related to bidding and bid information.
     */
    const socketConnection=async(lots)=>{
        const idToken = await Auth.currentSession()

        if(socketConn==null){
            const socket= io(config.SOCKETURL, {
                transports: ['websocket'],
                auth: {
                    token: idToken.getIdToken().getJwtToken(),
                    type: "seller"
                },
                reconnection: true
            })

            setSocket(socket);
            setIsSocket(true);

            lots.map((item)=>{
                socket.on('authorization', (message)=>{
                    if(message.success_status==true){
                        socket.emit('joinBidRoom', item?._id);
                    }
                })
            })        


            socket.on('placeBid',(message)=>{
                console.log(message,'placeBid')
                if(message.success==true){
                    const currentLotDetails=lotDetailsFromSocket;
                    // currentLotDetails[message.currentLotDetails._id]=message.currentLotDetails
                    const newLotDetails = { ...currentLotDetails, [message.currentLotDetails._id]: message.currentLotDetails };
                    setLotDetailsFromSocket(newLotDetails);
                }
                
                // socket.on('sellerListBidHistory', (message)=>{
                //     console.log(message, 'sellerListBidHistory inside')

                //     if(message?.response.all_bidders?.length>0  && message?.response.all_bidders[0]?.lot_id !== undefined){
                //         const newBidsList = { [message?.response.all_bidders[0].lot_id]: message.response };
                //         dispatch(setBids(newBidsList));
                //     }
                    
                // })
                
            })

            socket.on('sellerBidInformation',(message)=>{
                setBidInformation(message)
                console.log(message,'sellerBidInformation')
            })

        }
    }

    /**
     * The function `viewDomain` makes an asynchronous call to a data service to retrieve the subdomain
     * and sets it in the state.
     */
    const viewDomain = async () => {
        const endpoint="?view=True";
        const body={}
        const response=await dataService.SubDomainSetup(endpoint,body);
        setDomain(response.data.subdomain);
    }

    /**
    * The function `fetchHandler` fetches data from an API endpoint using the `dataService.LotList`
    * method and sets the retrieved data to the `lots` state variable.
    */
    const fetchHandler = useCallback(async (limit = 10) =>{
        let endpoint = `?auction_id=${uid}&per_page=${limit}`
        if(searchParams.get('sort')!==null){
            endpoint += `&sort_by=${String(searchParams.get('key'))}&sort_order=${String(searchParams.get('order'))}`
        }
        if(searchParams.get('search')!==null){
            endpoint += `&search_keyword=${searchParams.get('search')}`
        }
        if(searchParams.get('lotExport')!==null){
            endpoint += `&export=True`
        }
        const response = await dataService.LotList(endpoint);
        console.log("response",response)
        if(response.error===false){
            setLots(response.data.data);
            setLotInfo(response.data);
            setIsSearched(false)
        }
        if(response.data.csv_url){
            window.location.href= response.data.csv_url;
            let params = Object.fromEntries(searchParams);
            delete params.lotExport
            setSearchParams({...params})

        }
        setLoadLots(false)

    }, [location, searchParams])

    /**
     * The function `handleSearch` takes in an event, a key, and a value, and updates the search
     * parameters based on the key-value pair.
     */
    const handleSearch = (e, key, value) => {
        e.preventDefault();
        let params = Object.fromEntries(searchParams);
        params.page = 1
        if(key === 'search') {
            params[key] = encodeURIComponent(value); 
        }else {
            params[key] = value
        }
        if(params['search'] === ''){ 
            delete params.search
            setLoadLots(true);
            setIsSearched(true)        
        }
        setSearchParams({ ...params})
    }
    /**
     * The function `handleSort` is used to update the search parameters based on the sorting key and
     * order.
     */
    const handleSort = (e, key) => {
        let params = Object.fromEntries(searchParams);
        if(searchParams.get('sort') === false || searchParams.get('sort') === null) {
            params['sort'] = 'true'
            params['key'] = `${key}`
            params['order'] = `asc`
            setCurrentSortColumn({
                key: key,
                order: 'asc'
            });
        }else {
            if(searchParams.get('key').includes(key)) 
            {
                if(searchParams.get('order')==='asc') {
                    params['order'] = `dsc`
                    setCurrentSortColumn({
                        key: key,
                        order: 'dsc'
                    });
                }else{
                    params['order'] = `asc`
                    setCurrentSortColumn({
                        key: key,
                        order: 'asc'
                    });
                }
            }
            else {
                params['key'] = key
                params['order'] = `asc`
                setCurrentSortColumn({
                    key: key,
                    order: 'asc'
                });
            }
        }
        setSearchParams({ ...params})
    }


    const bidsFetchHandler = useCallback(async (page) =>{
        setLoadBids(true)
        let endpointBids = `per_page=${10}&page=${page ? page : '1'}`

        if(searchParam.get('sort')!==null){
            endpointBids += `&sort_by=${String(searchParam.get('sort'))}&sort_order=${String
            (searchParam.get('order'))}`
        }
        if(searchParam.get('search_term')!==null){
            endpointBids += `&search_keyword=${searchParam.get('search_term')}`
        }
        if(searchParam.get('bidsExport') !==null){
            // setLoader(true);
            endpointBids += `&export=True`
        }
        const response = await dataService.getAllBids(uid,endpointBids);
        setLoadBids(false)
        if(response.error===false){
            setBidss(response.data.data);
            setBidsInfo(response.data);
        }
        setBidsInfo(response.data);
        setIsSearched(false)
        if(response.data?.csv_url){
            window.location.href= response.data?.csv_url;
            let params = Object.fromEntries(searchParam);
            delete params.bidsExport
            setSearchParam(params)
        }
        
        // }
        
    }, [searchParam])

    /**
     * The function `handleImportFile` is used to handle the import of a CSV file, store it in a
     * specific location in an S3 bucket, and then call a data service to import the lot data from the
     * CSV file.
     */
    const handleImportFile = async (e) => {
        e.preventDefault();
        setImportLoader(true)
        let fileName= '';
        fileName = await Storage.put(`DomainName/Auctions/lots/${uuid()}/${uploadedFileName[0].name.replace(' ','')}`,uploadedFileName[0],{
            contentType: uploadedFileName[0].type,
            acl: "public-read"
        })
        let body={
            csv_url:`${config.Storage.CDN}${fileName.key}`,
            auction_id: uid
        }
        const data=await dataService.ImportLot(body)
        setShowImportModal(false)
        setImportLoader(false)
        setUploadedFileName('')
        window.scrollTo(0, 0)
        if(data.error){
            setUploadErrorMessage('CSV import unsuccessful due to error')
            setTimeout(() => {
                setUploadErrorMessage('')
            }, 3500);
        }
        else{
            setSuccessMessage('CSV import was successful')
            setTimeout(() => {
                setSuccessMessage('')
            }, 3500);
        }
        fetchHandler()
    }

    /**
     * The function `cloneHandler` clones an auction by sending a request to the server and then
     * updates the local storage and navigates to the auctions page if the cloning is successful.
     */
    const cloneHandler = async () => {
        const body={
            "auction_id": id,
        }
        const response = await dataService.CloneAuction(body);
        if(response.error===false){
            localStorage.setItem('cloneAuction',"true");
            Navigate('/auctions')
        }
    }

    useEffect(()=>{
        if(lots.length>0){
            socketConnection(lots)
        }
    },[lots])

    
    
    const context= {
        auctionData, 
        lots,
        lotInfo, 
        fetchHandler, 
        handleSearch, 
        handleSort, 
        currentSortColumn, 
        searchParams,
        loading, 
        isSearched, 
        setSuccessMessage, 
        handleImportFile, 
        uploadedFileName, 
        setUploadedFileName, 
        uploadErrorMessage,
        showImportModal, 
        setShowImportModal,
        importLoader, 
        setImportLoader, 
        isReorderLotModalOpen, 
        setIsReorderLotModalOpen, 
        lotDetailsFromSocket,setSearchParams,
        isSocket, loadLots, socketConn,bidsExportCsvUrl,
        bidsFetchHandler, bids, loadBids, setLoadBids, 
        bidsInfo, setSearchParam,searchParam, setCurrentSortColumn,
      
    }

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to fetch data and update the component's state. */
    useEffect(() => {
        window.scrollTo(0, 0);        

        localStorage.removeItem('lastRefresh');
        const value=localStorage.getItem('AuctionSuccess');
        const value1=localStorage.getItem('AuctionEditSuccess');
        const value2=localStorage.getItem('AuctionLiveSuccess');
        if(value==='true'){
            setSuccessMessage('Your auction has been created and saved as a draft')
            localStorage.setItem('AuctionSuccess','false')
            setTimeout(() => {
                setSuccessMessage('')
            }, 4000);
        }
        if(value1==='true'){
            setSuccessMessage('Your auction has been updated and saved as a draft')
            localStorage.setItem('AuctionEditSuccess','false')
            setTimeout(() => {
                setSuccessMessage('')
            }, 4000);
        }
        if(value2==='true'){
            setSuccessMessage('Your auction has been updated')
            localStorage.setItem('AuctionLiveSuccess','false')
            setTimeout(() => {
                setSuccessMessage('')
            }, 4000);
        }
        const lot=localStorage.getItem('lotSuccess');
        if(lot==='true'){
            setSuccessMessage('Lot successfully created')
            localStorage.setItem('lotSuccess','false')
            setTimeout(() => {
                setSuccessMessage('')
            }, 4000);
        }
        const update=localStorage.getItem('lotUpdated');
        if(update==='true'){
            setSuccessMessage('Lot successfully updated')
            localStorage.setItem('lotUpdated','false')
            setTimeout(() => {
                setSuccessMessage('')
            }, 4000);
        }
        // setLoading(false);
    },[]);

    useEffect(()=>{
        if(Object.keys(auctionData).length == 0){
            handleView()
        }
    },[auctionData])

    useEffect(() => {
        fetchHandler();
        viewDomain();
    }, [fetchHandler]);

    return (
        <>
            {!isAuctionInvalid && <div className="sm:mx-10 mx-5 sm:my-8 mt-8 mb-20">
                {successMessage!=='' && <SuccessMessage successMessage={successMessage}/>}
                {uploadErrorMessage!=='' && <ErrorMessage uploadErrorMessage={uploadErrorMessage}/>}
                {showExoprtToastMsg && <ToastMeassage message={'Export complete'}/>}
                {message!=="" && 
                <div className="mt-[15px] px-5 success-msg-csv py-3 bg-[#A8D9C8] border border-[#489892] rounded-[6px]">
                    <div className="flex items-center">
                        <img src="/images/verify-account.svg" alt="img" className="pr-3" />
                        <div>
                            <p className="text-[12px] leading-normal font-bold text-[#343434]">{message}</p>
                        </div>
                    </div>
                </div>}
                <Header auctionData={auctionData} lots={lots} cloneHandler={cloneHandler} lotInfo={lotInfo} 
                    handleView={handleView} domain={domain} bidInformation={bidInformation} bidsInfo={bidsInfo}  showExoprtToastMsg={showExoprtToastMsg}
                    setShowExportToastMsg={setShowExportToastMsg}
                    unpublishModalOpen={unpublishModalOpen} setUnpublishModalOpen={setUnpublishModalOpen}  setUploadErrorMessage={ setUploadErrorMessage}
                    isUnPublished={isUnPublished} setIsUnPublished={setIsUnPublished} setMessage={setMessage}/>
                {/* <!-- navpills content  --> */}
                {/* <!--Tabs navigation--> */}
                <div
                    className="my-4 flex list-none flex-row flex-wrap border-b-0 pl-0"
                    id="tabs-tab3">
                    <Link to={`/auctions/${id}/${uid}/overview`} role="presentation" id="tabs-home-tab3"
                        className={`focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 
                    ml-3 sm:mr-7 mr-3 pb-1 pt-2 text-[14px] font-medium  leading-normal text-[#343434] 
                    hover:isolate focus:isolate 
                    ${path.pathname===`/auctions/${id}/${uid}/overview`?'border-[#282828]':'border-transparent'}`}>
                        Overview
                    </Link>
                    <div role="presentation" className="relative">
                        <Link to={`/auctions/${id}/${uid}/lots`}
                            className={`focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 
                        ml-3 sm:mr-7 mr-3 pb-1 pt-2 text-[14px] font-medium  leading-normal text-[#343434] 
                        hover:isolate focus:isolate 
                        ${path.pathname===`/auctions/${id}/${uid}/lots`?'border-[#282828]':'border-transparent'}`}
                            id="tabs-profile-tab3">Lots</Link>
                    </div>
                    <Link to={`/auctions/${id}/${uid}/insights`} role="presentation" id="tabs-messages-tab3"
                        className={`focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 
                    ml-3 sm:mr-7 mr-3 pb-1 pt-2 text-[14px] font-medium  leading-normal text-[#343434] 
                    hover:isolate focus:isolate 
                    ${path.pathname===`/auctions/${id}/${uid}/insights`?'border-[#282828]':'border-transparent'}`}
                    >Insights
                    </Link>
                    <Link to={`/auctions/${id}/${uid}/bids`} role="presentation"
                        className={`focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 
                    ml-3 sm:mr-7 mr-3 pb-1 pt-2 text-[14px] font-medium  leading-normal text-[#343434] 
                    hover:isolate focus:isolate 
                    ${path.pathname===`/auctions/${id}/${uid}/bids`?'border-[#282828]':'border-transparent'}`}
                        id="bids_tab" data-testid="bids_tab">Bids
                    </Link>
                    <Link to={`/auctions/${id}/${uid}/bidders`} role="presentation"
                        className={`focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 
                    ml-3 sm:mr-7 mr-3 pb-1 pt-2 text-[14px] font-medium  leading-normal text-[#343434] 
                    hover:isolate focus:isolate 
                    ${path.pathname===`/auctions/${id}/${uid}/bidders`?'border-[#282828]':'border-transparent'}`}
                        id="tabs-insights-tab3" data-testid="bidder-list">Bidders
                    </Link>
                    <Link to={`/auctions/${id}/${uid}/sales`} role="presentation"
                        className={`focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 
                    ml-3 sm:mr-7 mr-3 pb-1 pt-2 text-[14px] font-medium  leading-normal text-[#343434] 
                    hover:isolate focus:isolate 
                    ${path.pathname===`/auctions/${id}/${uid}/sales`?'border-[#282828]':'border-transparent'}`}
                        id="tabs-insights-tab4" data-testid="sales_list">Sales
                    </Link>
                </div>
                {/* <!--Tabs content--> */}
                <div>
                    {/* {Object.keys(auctionData).length>0 && */}
                    <Outlet context={context}/>
                
                </div>
            
            </div>}
            {isAuctionInvalid && <Custom404 />}
        </>
    )
}
