import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import SetUpForMobile from "./setUpForMobile";

export default function Header(props){

    const { priceError,lotData,createLothandler,loading,auctionData,setLotData, setPriceError }=props;
    const {id,uid}=useParams();
    const [isOpen, setIsOpen] = useState(true)
    const Navigate=useNavigate();
    

    return(
        <>
            <nav className="h-[68px] md:px-10 px-5 flex border-b border-[#D4D4D8] justify-between 
                overflow-x-hidden sticky top-0 z-[101] bg-[#FFFFFF]">
                <div className="flex items-center">
                    <img src="/images/arrow-left-dark.svg" className="cursor-pointer"  alt="img" 
                        onClick={()=>Navigate(`/auctions/${id}/${uid}/lots`)}/>
                    <p className="md:block hidden pl-5 py-5">Lot creator</p>
                </div>
                <div className='flex justify-end items-center'>
                    <button id="preview-btn"  onClick={()=>setIsOpen((prevState) => !prevState)} 
                        className={`md:hidden block border border-[#282828] rounded-md text-[14px] text-[#282828] 
                leading-normal font-medium px-[20px] py-[12px] ${isOpen ? "w-[110px]" : "w-[165px]"} 
                md:w-[135px] bg-[#FFFFFF] mr-2`}>
                        {!isOpen?"Continue Editing":"Preview"}</button>
                    <button data-testid='save-lot' id="save-btn" onClick={(e)=>{e.preventDefault(); createLothandler()}}
                        disabled={priceError || lotData.title1==='' || lotData.description==='' || loading
                    || lotData.starting_price==='' || lotData.images.length===0 || 
                    (lotData.low_estimate!=="" && lotData.high_estimate=="") || 
                    (lotData.low_estimate=="" && lotData.high_estimate!=="")}
                        className=" bg-[#282828] rounded-md text-[14px] text-[#fff] disabled:cursor-auto
                        leading-normal font-medium px-[20px] py-[12px] w-[110px] md:w-[135px]
                        disabled:bg-[#D4D4D8] disabled:text-[hsl(240,4%,65%)] flex justify-center"
                    >Save
                        {loading&& <Oval
                            height={20}
                            width={50}
                            color="#FFFFFF"
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#000000"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />}
                    </button>
                </div>
            </nav>
            <SetUpForMobile setIsOpen={setIsOpen} isOpen={isOpen}
                lotData={lotData}
                auctionData={auctionData}
                setLotData={setLotData}
                priceError={priceError}
                setPriceError={setPriceError} 
            />
        </>
    );
}