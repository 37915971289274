import React, { useEffect } from 'react'
import LayoutNavbar from '../commonLayout/layoutNavbar'
import { useLocation } from 'react-router-dom';
import LotSection from '../commonLayout/lotSection';
import LayoutFooter from '../commonLayout/layoutFooter';
import LotListingHeader from '../commonLayout/lotListingHeader';
import { formatDate } from '../../../../CommonComponent/dateConverter';
import sanitizeHtml from 'sanitize-html';
import LiveTiming from '../commonLayout/liveTiming';
import { currencySymbolMapping } from '../../../../CommonComponent/staticValues';
import config from '../../../../config';
import { selectFontStyle } from '../../../../CommonComponent/SelectFontStyle';

export default function StandALonePreview({auctionData,startHour,startMin,endHour,endMin,endDate,startDate}) {
    const extend= `${auctionData.description.length>200 ? 'Read more' : ''}`
    const htmlContent =`${auctionData.description.slice(0, 200)} ${extend}`
    
    /**
     * The function "renderHTML" takes an HTML string, sanitizes it, and returns an object with a
     * property "__html" containing the sanitized HTML.
     * @returns An object with a property named "__html" that contains the sanitized HTML string.
     */
    function renderHTML(htmlString) {
        const sanitizedHTML = sanitizeHtml(htmlString);
      
        return { __html: sanitizedHTML };
    }
    
    const path=useLocation();

    useEffect(() => {
        localStorage.setItem('storedRoute', path.pathname);
    });

    return (
        <div 
            className="md:mt-9 mt-5 md:mx-10 mx-5 w-full overflow-x-hidden bg-[#FFF] 
        rounded-t-lg h-fit xl:w-[74%] lg:w-[70%] md:w-[60%]"
            style={{backgroundColor: auctionData.content_area['background_color'],color: auctionData.content_area['text_color']}}>
            {/* Main navigation container */}
            <LayoutNavbar auctionData={auctionData}/>
            <div className="w-full">
                <div className="flex sm:h-[520px] h-[350px] items-center bg-[#E6E6E6]">
                    <div className="flex justify-center items-center w-full flex-col">
                        <img 
                            src={auctionData.auction_image!==''?
                                `${config.Storage.CDN}${auctionData.auction_image}`:"/images/lead-auction-img.png"} 
                            alt="lead-auction-img" 
                            className={auctionData.auction_image!==''?'w-full sm:h-[520px] h-[350px]':'w-auto h-auto'} 
                            loading="lazy" />
                        {auctionData.auction_image===''&&<p className="text-[8px]  pt-2 text-center font-semibold 
                            leading-normal">Lead auction image</p>}
                    </div>
                </div>
            </div>
            <div className="lg:w-[50%] w-full mx-auto">  
                <div className="flex flex-col justify-center items-center">
                    <p className="text-[8px] pt-8 font-medium leading-normal text-center"
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                        {`${startDate ? formatDate(startDate) : '12 Jul 2023'} / ${
                            startHour!=='' ? String(startHour).padStart(2, '0') : '08'
                        }:${
                            startMin !== '' ? String(startMin).padStart(2, '0') : '00'
                        } ${auctionData.time_zone !== '' ? auctionData.time_zone.split(' - ')[0] : 'BST'} - ${
                            endDate!=='' ? formatDate(endDate) : '22 Jul 2023'
                        } / ${
                            endHour!=='' ? String(endHour).padStart(2, '0') : '20'
                        }:${
                            endMin !== '' ? String(endMin).padStart(2, '0') : '00'
                        } ${auctionData.time_zone !== '' ? auctionData.time_zone.split(' - ')[0] : 'BST'}`}
                    </p>                    
                    <p className="text-[23px] pt-2 leading-normal font-medium text-center"
                        style={{fontFamily: selectFontStyle(auctionData.font['hearder_font'])}}>
                        {auctionData.title===''?'Auction Title goes here':auctionData.title}</p>
                    <LiveTiming auctionData={auctionData}/>
                    {/* <!-- paragraph --> */}
                    <div className="flex  text-center items-center justify-center"
                        style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                        {auctionData.description===''?
                            <p className="pt-6 text-[9px] text-center leading-normal">
                                Description goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse 
                                fermentum mollis neque vel posuere. Consectetur adipiscing elit. Suspendisse fermentum mollis 
                                neque vel. 
                                <br/> <a href="#" className="underline">Read more</a></p>:
                            <div id="updated_auction-description" 
                                className="pt-6 text-[9px] text-center leading-normal  break-normal" 
                                style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}
                                dangerouslySetInnerHTML={renderHTML(htmlContent)}/>}
                    </div>
                    {/* <!-- clock content --> */}
                    <div className="flex xl:justify-center  justify-between w-full mx-10 pt-3 pb-4 border-[#E6E6E6] 
                    border-b  items-center" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                        <div className="px-[20px] text-center">
                            <img src="/images/clock-icon.svg" alt="clockicon" className=" xl:px-[50px] px-[25px]" />
                            <p className="text-[8px] pt-1 text-center font-[500] leading-normal">Timed Auction</p>
                        </div>
                        <div className="xl:px-[50px] px-[25px] text-center">
                            <p className="text-[18px] font-medium leading-normal">9</p>
                            <p className="text-[8px] text-center font-[500] leading-normal">No. of lots</p>
                        </div>
                        <div className="xl:px-[50px] px-[25px] text-center">
                            <p className="text-[18px] font-medium leading-normal">
                                {currencySymbolMapping[auctionData.currency]}</p>
                            <p className="text-[8px] text-center font-[500] leading-normal">Currency</p>
                        </div>
                    </div>
                    {/* <!-- join auction content --> */}
                    <div className="flex pt-[14px] px-5 w-full justify-between pb-4 border-[#E6E6E6] border-b ">
                        <div className="" style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                            <p className="text-[11px] font-[500] leading-normal">Join the auction</p>
                            <p className="text-[8px]  font-[500] leading-normal">
                                You must register to bid in this auction</p>
                        </div>
                        <div>
                            <button id="register" 
                                className="cursor-default bg-[#282828] rounded-md text-[9px] 
                            text-[#fff] leading-normal  font-medium px-[10px] py-[8px] w-[121px] md:w-[155px]"
                                style={{backgroundColor: auctionData.buttons['background_color'], 
                                    color: auctionData.buttons['text_color']}}>
                                Register for the auction</button>
                        </div>
                    </div>
                </div>
                {/* <!-- terms condition & social media links contents --> */}
                <div className="w-full m-0 flex  justify-between px-5 items-center" 
                    style={{fontFamily: selectFontStyle(auctionData.font['body_font'])}}>
                    <div className="flex  items-center">
                        <p className="text-[8px] text-center font-[600]  leading-normal">FAQs +</p>
                        <p className="text-[8px] pl-2 text-center font-[600] leading-normal">
                            Terms and Conditions +</p>
                    </div>
                    {/* <!-- social media link --> */}
                    <div className="flex   items-center">
                        <img src="/images/add-calendar-icon.svg" alt="add-calender-icon" className="cursor-default" />
                        <img src="/images/share-icon.svg" alt="share-icon" className="cursor-default" />
                    </div>
                </div>
                <LotListingHeader auctionData={auctionData}/>
            </div> 
            {/* <!-- listing-page --> */}
            <div className="border-t mt-3 sm:mb-20 mb-5 xl:px-[70px] lg:px-[50px] px-[20px] w-full border-[#E6E6E6]">
                <div className="flex flex-wrap -mx-5">
                    {[...Array(8)].map((item, index)=>(
                        <LotSection key={index} index={index} auctionData={auctionData} />
                    ))}
                </div>
            </div>
            {/* <!-- footer content --> */}
            <LayoutFooter auctionData={auctionData}/>
        </div>    
    )
}
