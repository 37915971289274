/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setFieldValue, setPasswordError, setEmailError, clearError, verifyOtp, setFirstUser, passwordFormate } from '../registrationSlice';
import RegistrationFooter from './registrationFooter';
import RegistrationHeader from './registrationHeader';
import RegistrationForm from './registrationForm';
import Otp from './otp';
import { dataService } from '../../../services/dataService';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export default function Register() {

    const registration = useSelector((state) => state.registration);
    const dispatch = useDispatch();

    const [passwordValid, setPasswordValid] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        symbol: false,
    });
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [recaptcha, setRecaptcha] = useState(false);
    const [loader, setLoader] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [otp, setOTP] = useState("");
    const [isOTPResent, setIsOTPResent] = useState(false);
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [timer, setTimer] = useState(10 * 3);
    const [captchaToken, setCaptchaToken] = useState('')
    const [encriptedToken, setEncriptedToken] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isMatch,setIsMatch]=useState(false);
    const [termsAcceptError, setTermsAcceptError] = useState('')
    const [otpSuccess, setOtpSuccess] = useState(true)

    const Navigate = useNavigate();

    useEffect(()=>{
        if(registration.termsAccepted===true){
            setRecaptcha(false)
        }
        else{
            setRecaptcha(true)
        }
    },[registration.termsAccepted])

    /**
     * The function `ValidateEmailId` checks if a given email address is valid according to a regular
     * expression pattern.
     * @returns The function `ValidateEmailId` returns a boolean value indicating whether the provided
     * email is valid or not.
     */
    const ValidateEmailId = (email) => {
        //const re = /^(([^<>()[\]\\.,;:\s@!"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const re=/^[a-zA-Z0-9._+]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    /**
     * The function `handleBlur` checks if the value of an input field is a valid email address and
     * dispatches an action to set an email error message if it is not.
     */
    const handleBlur = (e)=>{
        let {id,value} = e.target;
        if(value.trim()!==''){
            if(!ValidateEmailId(value)){
                dispatch(setEmailError('Please enter a valid email address'))
            }
            else
                dispatch(setEmailError(null))
        }
    }

    /**
     * The function checks if the password and confirm password fields in a registration form match,
     * and sets an error message if they don't.
     */
    const handlePasswordBlur=(e)=>{
        if(registration.password!=="" && registration.confirmPassword!==""){
            if(registration.password!==registration.confirmPassword){
                dispatch(setPasswordError('Passwords do not match'))
            }
        }
    }

    /**
     * The function handles input change events and performs password validation if the input id is
     * "password".
     */
    const handleInputChange = (e) => {
        let { id, value, checked } = e.target;
        value=value.replace(/\s/g, '');
        if(id==='confirmPassword'){
            dispatch(setFieldValue({ field: id, value: value}));
            dispatch(setFieldValue({ field: 'passwordError', value: null}));
            if(registration.password==value){
                setIsMatch(true);
            }else{
                setIsMatch(false);
            }
        }
        if(id==='email'){
            value=value.toLowerCase();
            dispatch(setFieldValue({ field: id, value: value}));
            dispatch(setFieldValue({ field: 'emailError', value: null}));
            setIsValidEmail(ValidateEmailId(value));
        }
        else if(id==='termsAccepted' || id==='newsletterSubscribed'){
            dispatch(setFieldValue({ field: id, value: checked }));
            setTermsAcceptError('')
        }
        else{
            if(id==='password'){
                // Perform password validation for each criterion
                const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
                setPasswordValid({
                    length: value.length >= 8 && value.length <= 16,
                    uppercase: /[A-Z]/.test(value),
                    lowercase: /[a-z]/.test(value),
                    number: /\d/.test(value),
                    symbol: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|='"`']/g.test(value),
                });
                if(value==registration.confirmPassword){
                    setIsMatch(true);
                    dispatch(setFieldValue({ field: 'passwordError', value: null}));
                }else{
                    setIsMatch(false);
                }
                
            }
            dispatch(setFieldValue({ field: id, value: value }));
        }
        
    };

    /**
     * The handleSubmit function is used to handle form submission, validate form inputs, and make an
     * API call to verify a reCAPTCHA token.
     */
    const handleSubmit = async(e, token=captchaToken)=>{
        if (e) {
            e.preventDefault();
        }
        let valid = true
        if(!isOTPResent){
            setLoader(true)
        }
        if(!ValidateEmailId(registration.email)){
            dispatch(setEmailError('Please enter a valid email address'))
            setLoader(false)
            valid=false
        }
        else if(registration.password!==registration.confirmPassword){
            dispatch(setPasswordError('Passwords do not match'))
            setLoader(false)
            valid=false
        }
        if(valid){
            const body = {
                "session_token": token,
                "email_address": registration.email,
                "password": registration.password,
                "confirm_password": registration.confirmPassword,
                "terms_and_condition": registration.termsAccepted,
                "newsletter_notification": registration.newsletterSubscribed,
                "user_type" : 'seller'
            }
            const response = await dataService.VerifyRecaptcha(body)
            if(response.data.status===201){
                setEncriptedToken(response.data.data.encrypted_token)
                setIsOTPSent(true)
                setOTP("")
                setOtpError("")
                setOtpSuccess(true)
                setTimeout(() => {
                    setOtpSuccess(false)
                }, 2000);
                setLoader(false)
            }
            else if(response.data.status===409){
                dispatch(setEmailError(response.data.data.message))
                setLoader(false)
            }
            setLoader(false)
        }
    }


    /**
     * The function `handleCaptcha` sets the values of `recaptcha` and `captchaToken` based on the
     * provided `token` parameter.
     */
    const handleCaptcha = (token)=>{
        if(token){
            setRecaptcha(true)
            setCaptchaToken(token)
        }
        else{
            setRecaptcha(false)
            setCaptchaToken('')
        }
    }

    /**
     * The function `otpVerification` is an asynchronous function that handles the verification of an
     * OTP (One-Time Password) for a user's registration process in a React application.
     */
    const otpVerification = async(e)=>{
        e.preventDefault();
        setLoader(true)
        const body = {
            "session_token": encriptedToken,
            "otp": otp
        } 
        const data = await dataService.VerifyOtp(body)
        if(data.data.status===201){
            localStorage.setItem('user',registration.email);
            const user = await Auth.signIn(registration.email, registration.password)
            dispatch(setFirstUser(user.attributes['custom:is_first_time_login']))
            dispatch(verifyOtp(registration.email))
            //sessionStorage.setItem('storedRoute', '/');
            Navigate("/");
        }
        else if(data.data.status === 400){
            setOtpError(data.data.data.message)
        }
        setLoader(false)
    }
    return (
        <>
            <div className="sm:bg-custom-image sm:h-screen bg-custom-image2 bg-cover bg-no-repeat bg-center">
                <div className="sm:px-10 px-6 py-8">
                    <RegistrationHeader/>
                    {!isOTPSent ? 
                        <RegistrationForm registration={registration} 
                            handleInputChange={handleInputChange} 
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                            showConfirmPassword={showConfirmPassword}
                            setShowConfirmPassword={setShowConfirmPassword}
                            passwordValid={passwordValid}
                            handleSubmit={handleSubmit}
                            handleCaptcha={handleCaptcha}
                            recaptcha={recaptcha}
                            loader={loader}
                            setRecaptcha={setRecaptcha}
                            handleBlur={handleBlur}
                            handlePasswordBlur={handlePasswordBlur}
                            isValidEmail={isValidEmail}
                            isMatch={isMatch}
                            termsAcceptError={termsAcceptError}
                            setTermsAcceptError={setTermsAcceptError}
                            
                        />
                        :
                        <Otp otp={otp}
                            setOTP={setOTP}
                            otpError={otpError}
                            setOtpError={setOtpError}
                            timer={timer}
                            setTimer={setTimer}
                            setIsOTPResent={setIsOTPResent}
                            loader={loader}
                            isOTPResent={isOTPResent}
                            otpVerification={otpVerification}
                            registration={registration}
                            handleSubmit={handleSubmit}
                            setIsOTPSent={setIsOTPSent}
                            captchaToken={captchaToken}
                            setCaptchaToken={setCaptchaToken}
                            recaptcha={recaptcha}
                            setRecaptcha={setRecaptcha}
                            otpSuccess={otpSuccess}
                            setOtpSuccess={setOtpSuccess}
                            setLoader={setLoader}
                        /> 
                    }
                    <RegistrationFooter/>
                </div>
            </div>
        </>
    )
}